var AppCtrl = function($scope, $timeout, $state, Auth, FirebaseRef, $firebaseObject, $firebaseArray, $uibModal, Users, $http, AclService, Mailer){
	$scope.search = false;
	$scope.colleague = {}

	var aclData = {
		member: ['view'],
		admin: ['edit']
	}
	AclService.setAbilities(aclData);

	Users.getCurrentUser().$loaded().then(function (user) {
		var role = user.role || 'admin';
	  AclService.attachRole(role);
	});


	$scope.renderLeftSidebar = function(){
		$timeout(function(){
			new Menu({
				wrapper: '#o-wrapper',
			  	type: 'push-left',
			  	menuOpenerClass: '.c-menu__close',
			  	maskId: '#c-mask'
			});
		})
	}

	$scope.renderRightSidebar = function(){
		$scope.notificationsBar = new Menu({
		  	wrapper: '#o-wrapper',
		  	type: 'slide-right',
		  	menuOpenerClass: '.c-button',
		  	maskId: '#c-mask'
		});
	}

	$scope.auth = Auth;
    // any time auth state changes, add the user data to scope
    $scope.auth.$onAuthStateChanged(function(firebaseUser) {
    	$scope.firebaseUser = firebaseUser;
		if(firebaseUser === null){
			$state.go('login');
		}
		else{
			$scope.currentUser = Users.getCurrentUser();
			$scope.currentUser.$loaded().then(function () {
				//Create Business
				if(!angular.isDefined($scope.currentUser.businesses)){
					var modalInstance = $uibModal.open({
						keyboard: false,
						backdrop: 'static',
						controller: 'AddBusinessCtrl',
						templateUrl: 'partials/modals/newBusiness.html'
					});
					modalInstance.result.then(function (result) {
						if (result) {
							$state.reload();
						}
					}, function () {});
				}
			});
		}
    });

	$timeout(function(){
		$scope.toggleSearch = function(){
			$scope.search = !$scope.search;
		}
	});

	$scope.openNotificationsBar = function(){
		$scope.notificationsBar.open();
	}

	$scope.roles = [
		{ val: 'admin', name: 'Admin' },
		{ val: 'member', name: 'Lecture' },
	];

	$scope.openInvitationForm = function () {
		if (angular.isDefined($scope.currentUser.businesses)) {
			$scope.businessesNames = []
			FirebaseRef.child('businesses').once('value', function(snapshot) {
				let records = snapshot.val()
				for (key in records) {
					if ($scope.currentUser.businesses[key]) {
						$scope.businessesNames.push({'name': records[key]['name'], 'key': key})
					}
				}
				$scope.colleague.business = $scope.businessesNames[0]
			}).then(function (prom) {
				$scope.modalInstance = $uibModal.open({
					templateUrl: 'partials/modals/invitationModal.html',
					scope: $scope,
					controller: 'AppCtrl',
					size: 'sm'
				});
			})
		}
	}

	$scope.sendInvitation = function () {
		if (document.getElementById('toast-container') !== null) {
			toastr.clear();
		}
		if(!($scope.colleague.name && $scope.colleague.email && $scope.colleague.business)) {
			$scope.close();
			return toastr.error('Data must be filled');
		}

		var token = function () {
			return Math.random().toString(36).substr(2).toUpperCase();
		};

		var tokenCreated = token();
		var createdPsswd = token();
		var createdData = {};

		createdData[$scope.colleague.email.replace(/\./g, ',')] = {inviter: $scope.currentUser.$id, token: {used: false}};
		createdData[$scope.colleague.email.replace(/\./g, ',')]['token'][tokenCreated] = true;
		FirebaseRef.child('pending-invitations/').update(createdData);
		$scope.close();
		Mailer.varifyUserExistence($scope.colleague.email).then(function (exists) {
			if (exists) {
				Mailer.send({email: $scope.colleague.email.replace(), flags: {exists: 1}});
				newBusiness = {};
				newBusiness[$scope.colleague.business] = true;
				return FirebaseRef.child('users/' + $scope.currentUser.$id + '/businesses').update(newBusiness)
			}
			Mailer.createUser({
				name: $scope.colleague.name,
				email: $scope.colleague.email,
				businessesNames: $scope.businessesNames,
				business: $scope.colleague.business,
				role: $scope.colleague.name,
				createdPsswd: createdPsswd,
				tokenCreated: tokenCreated
			}).then(function () {
				Mailer.send({email: $scope.colleague.email, tokenCreated: tokenCreated, createdPsswd: createdPsswd});
			}).catch(function (error) {
				toastr.error(error.message);
			});
			$scope.colleague = {business: $scope.businessesNames[0].name};
		})
	}

	$scope.close = function() {
		$scope.modalInstance.close()
	}

}

AppCtrl.$inject = ['$scope', '$timeout', '$state', 'Auth', 'FirebaseRef', '$firebaseObject', '$firebaseArray', '$uibModal', 'Users', '$http', 'AclService', 'Mailer'];

angular.module('ezTable.controllers')

.controller('AppCtrl', AppCtrl);
