angular.module('ezTable.directives')

.directive('capitalizeFirst', ['$parse', function($parse) {
   return {
       restrict: 'A',
       require: 'ngModel',
       link: function(scope, element, attrs, modelCtrl) {
           var capitalize = function(inputValue) {
               if (_.isEmpty(inputValue)){
                   inputValue = '';
               }
               var capitalized = inputValue.charAt(0).toUpperCase() + inputValue.substring(1);
               if(capitalized !== inputValue) {
                   modelCtrl.$setViewValue(capitalized);
                   modelCtrl.$render();
                }
                return capitalized;
            }
            modelCtrl.$parsers.push(capitalize);
            capitalize($parse(attrs.ngModel)(scope)); // capitalize initial value
        }
    }
}]);