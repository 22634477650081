
angular.module('ezTable.controllers')

.controller('ConfirmationCtrl', ['$scope', '$uibModalInstance', 'msg', function($scope, $uibModalInstance, msg){

    $scope.msg = msg;

    $scope.yes = function(){
		$uibModalInstance.close(true);
	}

	$scope.no = function(){
		$uibModalInstance.close(false);
	}

}]);