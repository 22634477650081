
angular.module('ezTable.controllers')

.controller('SignupCtrl', ['$scope', '$state', '$timeout', 'Auth', 'FirebaseRef', function($scope, $state, $timeout, Auth, FirebaseRef){

	$scope.regex = '^([1-zA-Z0-1@\*_.\s]{8,16})$';

	$scope.signup = function(){
		if(!$scope.signupForm.$valid){
			return false;
		}
		Auth.$createUserWithEmailAndPassword($scope.newUser.email, $scope.newUser.password)
			.then(function(firebaseUser){ $scope.handleLogin(firebaseUser, $scope.newUser) })
			.catch(function(error){ $scope.handleError(error) });
	}

}]);