(function () {

    var dependencies = [
        'ezTable.services',
        'ezTable.controllers',
        'ezTable.directives',
        'ezTable.filters',
        'ui.router'
    ];

    var views = 'partials/views/';

    var ezTable = angular.module('ezTable', dependencies);

    var config = {
        apiKey: "AIzaSyBT0J4nnPxcppjP68NozgyGajiglYQJvbw",
        authDomain: "ez-table-80d9c.firebaseapp.com",
        databaseURL: "https://ez-table-80d9c.firebaseio.com",
        storageBucket: "ez-table-80d9c.appspot.com"
    }

    firebase.initializeApp(config);

    ezTable.config(['$stateProvider', '$urlRouterProvider', function ($stateProvider, $urlRouterProvider) {

            $urlRouterProvider.otherwise("/404");
            $urlRouterProvider.when("", "/");

            $stateProvider
                    .state('app', {
                        abstract: true,
                        url: '/',
                        template: '<ui-view/>'
                    })
                    .state('login', {
                        parent: 'app',
                        url: '',
                        templateUrl: views + 'landingPage/landingPage.html',
                        controller: 'LandingPageCtrl',
                        onEnter: ['$state', 'Auth', function ($state, Auth) {
                                if (Auth.$getAuth() !== null) {
                                    //$state.go('dashboard');
                                }
                            }]
                    })
                    .state('main', {
                        parent: 'app',
                        url: 'main',
                        templateUrl: 'partials/layout/loggedIn.html',
                        resolve: {
                            "currentAuth": ["Auth", function (Auth) {
                                    return Auth.$requireSignIn();
                                }]
                        }
                    })
                    .state('dashboard', {
                        parent: 'main',
                        url: '/dashboard',
                        views: {
                            'content': {
                                templateUrl: views + 'smartTableTabs.html',
                                controller: 'DashboardCtrl'
                                        // templateUrl: views + "dashboard.html",
                                        // controller: 'DashboardCtrl'
                            }
                        }

                    })
                    .state('table', {
                        parent: 'dashboard',
                        url: '/:key',
                        views: {
                            'display': {
                                templateUrl: views + 'tableContent.html',
                                controller: 'TabsCtrl'
                            }
                        }
                    })
                    .state('deadlines', {
                        parent: 'dashboard',
                        url: '/deadlines',
                        views: {
                            display: {
                                templateUrl: views + 'deadlines.html'
                            }
                        }
                    })
                    .state('invited', {
                        parent: 'app',
                        url: 'join/:token/:email',
                        controller: function ($state, $stateParams, $firebaseObject) {
                            $firebaseObject(firebase.database().ref().child('pending-invitations').child($stateParams.email.replace(/\./g, ','))).$loaded()
                                    .then(function (snap) {
                                        var tokenObj = snap;

                                        try {
                                            if (tokenObj.token[$stateParams.token]) {
                                                toastr.warning("Remember to change your password!");
                                                tokenObj.used = true;
                                                tokenObj.$save();
                                                $state.go('login');
                                            }
                                        } catch (e) {
                                            $state.go('404');
                                        }
                                    })
                                    .catch(function (error) {
                                        if (error)
                                            $state.go('404');
                                    })
                        }
                    })
                    .state('404', {
                        url: "/404",
                        template: "<div>Not found</div>"
                    })
        }]);

    ezTable.run(['editableOptions', '$rootScope', '$state', 'Auth', '$templateCache', '$http', '$window', '$location', function (editableOptions, $rootScope, $state, Auth, $templateCache, $http, $window, $location) {
        editableOptions.theme = 'bs3';
        $window.ga('create','UA-91617256-1', 'auto');
            $rootScope.$on("$stateChangeError", function (event, toState, toParams, fromState, fromParams, error) {
                if (error === "AUTH_REQUIRED") {
                    $state.go('login');
                }
            });

            $rootScope.$on("$stateChangeSuccess", function (event, toState, toParams, fromState, fromParams, options) {
                $window.ga('send','pageview', $location.path());
            });

            var views = 'partials/views/';
            var request;

            request = views + 'input-checklist.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-checklist.html', tmpl.data);
            });
            request = views + 'input-currency.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-currency.html', tmpl.data);
            });
            request = views + 'input-date.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-date.html', tmpl.data);
            });
            request = views + 'input-email.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-email.html', tmpl.data);
            });
            request = views + 'input-entity.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-entity.html', tmpl.data);
            });
            request = views + 'input-file.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-file.html', tmpl.data);
            });
            request = views + 'input-formula.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-formula.html', tmpl.data);
            });
            request = views + 'input-notes.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-notes.html', tmpl.data);
            });
            request = views + 'input-number.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-number.html', tmpl.data);
            });
            request = views + 'input-select.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-select.html', tmpl.data);
            });
            request = views + 'input-telephone.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-telephone.html', tmpl.data);
            });
            request = views + 'input-text.html';
            $http.get(request).then(function (tmpl) {
                $templateCache.put('input-text.html', tmpl.data);
            });

            $rootScope.$on('$stateChangeStart', function (event, toState, toParams, fromState, fromParams) {
                if (toState.name === 'dashboard') {
                    event.preventDefault();
                    $state.go('table', {key: 'deal'});
                }
                if(toState.name === 'login'){
                    firebase.auth().onAuthStateChanged(function(user) {
                        if (user) {
                            event.preventDefault();
                            $state.go('dashboard');
                        }
                    });
                }
            });

        }]);

}());
