angular.module('ezTable.directives')

.directive('stSearchOutside', ['$timeout', function ($timeout) {
    return {
        require:'^stTable',
        scope: {
            stSearchOutside:'='
        },
        link: function(scope, element, attr, ctrl){
            scope.$watch('stSearchOutside', function(val){
                return ctrl.search(val);
            });
        }
    }
}]);