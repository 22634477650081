
angular.module('ezTable.controllers')

        .controller('TabsCtrl', ['$scope', 'Users', 'FirebaseRef', '$firebaseArray', '$timeout', '$http', '$uibModal', '$stateParams', function ($scope, Users, FirebaseRef, $firebaseArray, $timeout, $http, $uibModal, $stateParams) {

                $scope.shareEmail = {email: '', tabKey: ''};

                $scope.types = [
                    {type: 'text', name: 'Text'},
                    {type: 'select', name: 'Input with Options'},
                    {type: 'date', name: 'Date'},
                    {type: 'number', name: 'Number'},
                    {type: 'file', name: 'File'},
                    {type: 'checklist', name: 'Checklist'},
                    {type: 'notes', name: 'Notes'},
                    {type: 'formula', name: 'Formula'}
                ];

                $scope.updateTab = function (tab2) {
                    Users.getCurrentUser().$loaded().then(function (data) {
                        var business = data.currentBusiness,
                                user = data.$id;
                        var businessRef = FirebaseRef.child('businesses/' + business),
                                businessTablesRef = businessRef.child('eztables/' + tab2.key),
                                userTablesRef = FirebaseRef.child('users/' + user).child('businesses/' + business).child('eztables/' + tab2.key),
                                rowsRef = businessRef.child('information').child(tab2.key),
                                businessTables = $firebaseArray(businessTablesRef),
                                userTables = $firebaseArray(userTablesRef);

                        tab2.rowCollection = $firebaseArray(rowsRef);
                    });
                };

                $scope.toggleCustomWizard = function () {
                    $scope.tab.creating = !$scope.tab.creating;
                };

                $scope.getTables = function () {

                    Users.getCurrentUser().$loaded().then(function (data) {
                        var business = data.currentBusiness,
                                user = data.$id;

                        var businessRef = FirebaseRef.child('businesses/' + business),
                                businessTablesRef = businessRef.child('eztables/' + $scope.tab.key),
                                userTablesRef = FirebaseRef.child('users/' + user).child('businesses/' + business).child('eztables/' + $scope.tab.key),
                                rowsRef = businessRef.child('information').child($scope.tab.key),
                                businessTables = $firebaseArray(businessTablesRef),
                                userTables = $firebaseArray(userTablesRef);

                        var getTCollection = function (tables) {
                            var tCollection = [];
                            _.each(tables, function (t) {
                                var el = {
                                    title: t.title,
                                    $id: t.$id
                                }
                                tCollection.push(el);
                            });
                            return tCollection;
                        }

                        businessTables.$watch(function () {
                            $scope.tab.groups[1].tables = getTCollection(businessTables);
                        });

                        userTables.$watch(function () {
                            $scope.tab.groups[2].tables = getTCollection(userTables);
                        });

                        $scope.tab.rowCollection = $firebaseArray(rowsRef);
                        $scope.tab.groups = [
                            {
                                label: "Default",
                                tables: [{$id: 'default', title: $scope.tab.title}]
                            },
                            {label: "Business", tables: []},
                            {label: "User", tables: []}
                        ];

                        $scope.tab.workingTable = {};

                        $scope.tab.selectedTable = $scope.tab.groups[0].tables[0].$id;
                        $scope.getTable();
                        $scope.tab.loaded = true;
                    });
                }

                $scope.getTable = function () {
                    var table = null;
                    for (var i = 0; i < $scope.tab.groups.length; i++) {
                        table = _.filter($scope.tab.groups[i].tables, {$id: $scope.tab.selectedTable});
                        if (table.length > 0) {
                            $scope.tab.workingGroup = $scope.tab.groups[i].label.toLowerCase();
                            break;
                        }
                    }
                }

                $scope.getTab = function () {
                    return $scope.tab;
                }

                $scope.selectTabTable = function (section, key) {
                    $scope.tab.creating = false;
                    $scope.tab.selectedTable = key;
                    $scope.getTable();
                }

                var getRef = function (group) {
                    var ref = {
                        firebase: null
                    };
                    switch (group) {
                        case 'business':
                            ref.firebase = FirebaseRef.child('businesses/' + $scope.currentUser.currentBusiness);
                            break;
                        case 'group':
                            break;
                        case 'user':
                            ref.firebase = FirebaseRef.child('users/' + $scope.currentUser.$id).child('businesses/' + $scope.currentUser.currentBusiness);
                            break;
                        default:
                            break;
                    }
                    return ref;
                }

                $scope.removeTable = function () {
                    var group = $scope.tab.workingGroup,
                            tableId = $scope.tab.selectedTable,
                            ref = getRef(group.toLowerCase()),
                            tablesRef = ref.firebase.child('eztables/' + $scope.tab.key);
                    tablesRef.child(tableId).remove();
                    $scope.tab.selectedTable = $scope.tab.groups[0].tables[0].$id;
                    $scope.getTable();
                    tablesRef.on('child_removed', function (data) {
                        $timeout(function () {
                            toastr.success('Table removed');
                        });
                    });
                }

                $scope.openSendTableModal = function (tabKey) {
                    $scope.shareEmail.tabKey = tabKey;
                    $scope.modalInstance = $uibModal.open({
                        templateUrl: 'partials/modals/emailTableModal.html',
                        scope: $scope,
                        size: 'sm'
                    });
                };

                $scope.sendTable = function () {
                    if (angular.isDefined($scope.shareEmail.email) && $scope.shareEmail.email.length > 5) {
                        Users.getCurrentUser().$loaded().then(function (user) {
                            if (document.getElementById('toast-container') !== null) {
                                toastr.clear();
                            }
                            var email = $scope.shareEmail.email;
                            var options = {
                                method: 'POST',
                                url: 'https://ondecode-excel.herokuapp.com/convert-table/ezTable' + '/' + user.currentBusiness + '/' + $scope.shareEmail.tabKey + '?doctype=excel',
                                params: {'email': email}
                            }
                            $scope.shareEmail = {};
                            $scope.close();

                            try {
                                $http(options).then(function (response) {
                                    if (response.status !== 200) {
                                        return toastr.error("Couldn't send email");
                                    }
                                    toastr.success('Table sent to ' + email);

                                }).catch(function (error) {
                                    toastr.error('There was an error: ' + error);
                                });
                            } catch (e) {
                                toastr.error('There was an error: ' + (e.message ? e.message : e));
                            }
                        });
                    }
                };

                $scope.close = function () {
                    $scope.modalInstance.close()
                    $scope.shareEmail = {}
                };

                $scope.downloadTable = function (tabKey) {
                    Users.getCurrentUser().$loaded().then(function (user) {
                        location.href = "https://ondecode-excel.herokuapp.com/convert-table/ezTable/" + user.currentBusiness + '/' + tabKey + '?doctype=excel'
                    });
                };


                $scope.downloadAsPDF = function (tabKey) {
                  Users.getCurrentUser().$loaded().then(function (user) {
                    location.href = "https://ondecode-excel.herokuapp.com/convert-table/ezTable/" + user.currentBusiness + '/' + tabKey + '?doctype=pdf'
                  });
                };

                Users.getCurrentUser().$loaded().then(function (data) {
                    var business = data.currentBusiness,
                            user = data.$id;

                    var businessRef = FirebaseRef.child('businesses/' + business),
                            entitiesRef = businessRef.child('default/entities'),
                            entities = $firebaseArray(entitiesRef);

                    entities.$loaded().then(function () {
                        var tabsList = _.filter(entities, function (e) {
                            return e.key === $stateParams.key;
                        });
                        _.forEach(tabsList, function (tab) {
                            if (tab.key === 'acquisition' || tab.key === 'deal' || tab.key === 'property' || tab.key === 'tickets') {
                                tab.group = 0;
                            } else if (tab.key === 'deadlines') {
                                tab.group = 2;
                            } else {
                                tab.group = 1;
                            }
                        });
                        $scope.tab = tabsList[0];
                        $scope.getTables($scope.tab);
                    });
                });
            }]);
