
angular.module('ezTable.controllers')

.controller('NotesCtrl', ['$scope', '$uibModalInstance', 'parent', 'target', 'Users', function($scope, $uibModalInstance, parent, target, Users){
    $scope.parent = parent
    $scope.target = target
    $scope.item = _.get($scope.parent, $scope.target);
    $scope.update = true
    $scope.addNote = function (isValid) {
        if(!isValid){
            return false;
        }
        if (!angular.isDefined(_.get($scope.parent, $scope.target))) {
            var aux = {
                notes: []
            };
            _.set($scope.parent, $scope.target, aux);

        }
        var user = Users.getSessionUser();

        $scope.newNote.user = {
            name: user.displayName,
            img: user.photoURL
        };
        $scope.newNote.created_at = moment().format();
        $scope.item = _.get($scope.parent, $scope.target);
        $scope.item.notes.push($scope.newNote);
        $scope.update ? $scope.updateItem($scope.parent) : '';
        $scope.newNote = {};
    }

    $scope.createNotes = function(){
        $scope.addToTable($scope.parent);
    }

    $scope.removeNote = function (note, event) {
        var modalInstance = $uibModal.open({
            templateUrl: 'partials/modals/confirmationModal.html',
            controller: 'ConfirmationCtrl',
            // appendTo: $(event.target).closest('.notes-container'),
            resolve: {
                msg: function () {
                    return "Are you sure you want to remove this note?";
                }
            }
        });
        modalInstance.result.then(function (result) {
            if (result) {
                var item = _.get($scope.parent, $scope.target),
                    pos = item.notes.indexOf(note);
                item.notes.splice(pos, 1);
                $scope.updateItem($scope.parent)
            }
        }, function () {});
    }
  $scope.close = function () {
    $uibModalInstance.close()
  }
}]);
