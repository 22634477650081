angular.module('ezTable.services')

.factory('Users', ['$firebaseArray', '$firebaseObject', 'FirebaseRef', 'Auth', function ($firebaseArray, $firebaseObject, FirebaseRef, Auth) {
    var usersRef = FirebaseRef.child("users"),
    	users = $firebaseArray(usersRef),
    	Users = {
            getProfile: function(uid){
    			return $firebaseObject(usersRef.child(uid));
  			},
  			getDisplayName: function(uid){
    			return users.$getRecord(uid).displayName;
  			},
			getCurrentBusiness: function(){
    			return users.$getRecord(Auth.$getAuth().uid).currentBusiness;
  			},
			getSessionUser: function(){
				return users.$getRecord(Auth.$getAuth().uid);
			},
            getCurrentUser: function(){
                var currentUserUID = Auth.$getAuth().uid;
                return $firebaseObject(usersRef.child(currentUserUID));
            },
  			all: users
    	};

    return Users;
}]);