
angular.module('ezTable.controllers')

        .controller('DashboardCtrl', ['$scope', 'Users', 'FirebaseRef', '$firebaseArray', '$timeout', '$rootScope', '$state', function ($scope, Users, FirebaseRef, $firebaseArray, $timeout, $rootScope, $state) {

                Users.getCurrentUser().$loaded().then(function (data) {

                    var business = data.currentBusiness,
                            user = data.$id;

                    var businessRef = FirebaseRef.child('businesses/' + business),
                            entitiesRef = businessRef.child('default/entities'),
                            entities = $firebaseArray(entitiesRef);

                    entities.$loaded().then(function () {
                        var tabsList = _.filter(entities, function (e, index) {
                            return e.table;
                        });
                        _.forEach(tabsList, function (tab) {
                            if (tab.key === 'acquisition' || tab.key === 'deal' || tab.key === 'property' || tab.key === 'tickets') {
                                tab.group = 0;
                            } else if (tab.key === 'deadlines') {
                                tab.group = 2;
                            } else {
                                tab.group = 1;
                            }
                        });
                        $scope.tabset = {
                            tabs: tabsList
                        };
                    });
                });

                $scope.updateTitulo = function () {
                    $timeout(function () {
                        $scope.titulo = $state.params.key;
                    });

                };
                $scope.updateTitulo();                

            }]);
