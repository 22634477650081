
angular.module('ezTable.controllers')
        .controller('ColumnCtrl', ['$scope', '$uibModalInstance', 'FirebaseRef', '$firebaseArray', 'Users', '$alert', 'columns', 'multiple', 'column', 'modifying', function ($scope, $uibModalInstance, FirebaseRef, $firebaseArray, Users, $alert, columns, multiple, column, modifying) {
                $scope.edit = column !== null;
                $scope.column = column ? angular.copy(column) : {};
                $scope.newOption = {};
                $scope.formulaColumns = columns;
                $scope.multiple = multiple;
                $scope.newFormula = {};
                $scope.isFormula = false;
                $scope.fromSelect = {value: [], bool: false, key: "", isCurrency: false};
                $scope.canCreate = false;
                $scope.numberFormat;
                if ($scope.edit) {
                  $scope.numberFormat = $scope.column.format || 'format'
                }
                $scope.customColumns = {
                    title: 'Custom Columns',
                    columns: []
                };

                $scope.tootlipTitle = $scope.edit ? "Column Data types cannot be changed once created to prevent unexpected behaivors." : "Data type will help you to keep your data consistent and avoid information errors that could lead to problems.";

                Users.getCurrentUser().$loaded().then(function (data) {
                    var business = data.currentBusiness,
                            user = data.$id;

                    var columnsRef = FirebaseRef.child('businesses/' + business).child('custom-columns/' + $scope.section);
                    $scope.availableColumns = $firebaseArray(columnsRef);
                });

                if (angular.isDefined($scope.columns['custom'])) {
                    if ($scope.columns['custom'].columns.length > 0) {
                        $scope.customColumns.columns = angular.copy($scope.columns['custom'].columns);
                    }
                }

                $scope.addOption = function (option) {
                    if (!angular.isDefined($scope.column.options)) {
                        $scope.column.options = [];
                    }
                    $scope.column.options.push(option);
                    $scope.newOption = {};
                }

                /* Formula handling propose */
                $scope.appendValue = function (col) {
                    if (!$scope.newFormula.equation) {
                        $scope.newFormula.equation = '"' + col.title + '"';
                        $scope.newFormula.withKeys = col.key;
                    } else {
                        $scope.newFormula.equation += '"' + col.title + '"';
                        $scope.newFormula.withKeys += col.key;
                    }

                    $scope.fromSelect.bool = true;
                    $scope.fromSelect.value.push('"' + col.title);
                    $scope.fromSelect.key = col.key;
                    $scope.fromSelect.isCurrency = (col.type === 'currency');
                }
                var count = 0;
                $scope.$watch('newFormula.equation', function (newV, oldV) {
                    if (newV !== oldV) {
                        if (newV === undefined) {
                            $scope.newFormula.withKeys = "";
                        } else if (oldV !== undefined) {
                            if (oldV.length > newV.length) {
                                count++;
                                if ($scope.fromSelect.bool === true) {
                                    if (count % 2 == 1) {
                                        $scope.newFormula.equation = $scope.newFormula.equation.replace($scope.fromSelect.value.pop(), "");
                                        $scope.newFormula.withKeys = $scope.newFormula.withKeys.replace($scope.fromSelect.key, "");
                                        angular.element('#newFormulaEquation').val($scope.newFormula.equation);
                                    }
                                    if ($scope.newFormula.equation[$scope.newFormula.equation.length - 1] === '"') {
                                        $scope.fromSelect.bool = true;
                                    } else {
                                        $scope.fromSelect.bool = false;
                                    }
                                } else {
                                    $scope.newFormula.withKeys = $scope.newFormula.withKeys.slice(0, -1);
                                    if ($scope.newFormula.equation[$scope.newFormula.equation.length - 1] === '"') {
                                        $scope.fromSelect.bool = true;
                                        count = 0;
                                    }
                                }
                            } else {
                                let newChar = $scope.newFormula.equation[$scope.newFormula.equation.length - 1];
                                let appendRegExp = new RegExp(/(\+|\s|\-|\*|\/|[0-9]|\.|\)|\()+/);
                                if (appendRegExp.test(newChar)) {
                                    $scope.newFormula.withKeys = $scope.newFormula.withKeys ? ($scope.newFormula.withKeys += newChar) : newChar;
                                    $scope.fromSelect.bool = false;
                                }
                            }
                        }
                        angular.element('#newFormulaEquation').focus();
                    }
                });

                $scope.saveFormula = function (formula) {
                    $scope.column.formula = formula.withKeys;
                    $scope.column.hint = formula.equation;
                    $scope.column.isCurrency = $scope.fromSelect.isCurrency;
                    $scope.canCreate = true;
                    count = 0;
                }
                /* end add formula propose */

                $scope.setNumberFormat = function (type) {
                    $scope.column.format = type;
                }

                $scope.generateKey = function (title) {
                    if (!_.isEmpty(title) && !$scope.edit) {
                        // return $scope.column.key = $scope.section + '_' + title.replace(/\s+/g, '').toLowerCase();
                        return $scope.column.key = $scope.section + '_' + moment.now().toString();
                    }
                }

                var pushToColumns = function () {
                    $scope.customColumns.columns.push($scope.column);
                    $scope.column = {};
                }

                $scope.removeOption = function (option) {
                    var pos = $scope.column.options.indexOf(option);
                    $scope.column.options.splice(pos, 1);
                }

                $scope.updateColumn = function () {
                  console.log($scope.column);
                }

                $scope.saveColumn = function () {
                    if ($scope.column.type === 'number' && !$scope.column.format) {
                        $scope.setNumberFormat($scope.numberFormat);
                    }
                    var title = $scope.column.title;
                    // $scope.column.key = generateKey($scope.column.title);
                    $scope.column.title = title.charAt(0).toUpperCase() + title.slice(1);
                    $scope.column.created = true;
                    $scope.multiple ? pushToColumns() : $uibModalInstance.close($scope.column);
                }

                $scope.addSelection = function ($item, $model, $label, $event) {
                    $alert({
                        title: 'Column Already Added!',
                        content: 'When adding a column from the dropdown, it may already contain information.',
                        placement: 'top',
                        type: 'info',
                        show: true,
                        duration: 3,
                        container: '.modal-body.creation-wizard'
                    });
                    $scope.column = angular.copy(_.omit($model, ['$priority']));
                    $scope.column.db = true;
                    $scope.column.created = true;
                    $scope.multiple ? pushToColumns() : $uibModalInstance.close($scope.column);
                }

                $scope.removeColumn = function (column) {
                    var pos = $scope.customColumns.columns.indexOf(column);
                    $scope.customColumns.columns.splice(pos, 1);
                }

                $scope.saveChanges = function () {
                    $uibModalInstance.close($scope.column);
                }


                $scope.close = function () {
                    $uibModalInstance.close(false);
                }

            }]);
