
angular.module('ezTable.controllers')

.controller('CellCommentsCtrl', ['$scope', '$uibModalInstance', 'title', function ($scope, $uibModalInstance, title) {

    $scope.title = title;

    $scope.addComment = function (isValid) {
        if (!isValid) { return false; }
        var comment = {
            text: $scope.comment
        };
        $scope.comment = null;
        $uibModalInstance.close(comment);
    }

    $scope.close = function(){
		$uibModalInstance.close(false);
	}

}]);