angular.module('ezTable.directives')

        .directive('smartTable', ['$firebaseObject', '$uibModal', 'Users', 'FirebaseRef', '$firebaseArray', 'AclService', '$timeout', '$templateCache', '$location', function ($firebaseObject, $uibModal, Users, FirebaseRef, $firebaseArray, AclService, $timeout, $templateCache, $location) {
                return {
                    restrict: 'E',
                    replace: true,
                    scope: {
                        rowCollection: '=rows',
                        search: '=',
                        section: '=',
                        groups: '=',
                        types: '=',
                        table: '=',
                        isDeadline: '='
                    },
                    transclude: true,
                    templateUrl: '/partials/directives/smart-table.html',
                    link: function postLink(scope, element, attrs, ngModel) {

                    },
                    controller: function ($scope) {
                        {
                            var loaded = false;

                            $scope.updateTable = function () {
                                var path = $location.path().split('/');
                                if (path[path.length - 1] === 'property') {
                                    $timeout(function () {
                                        $('#printableTable').find('table').tableHeadFixer({'left': 6});
                                        $('#printableTable').find('table').find('#ui-sortable').find('th').css("z-index", "+=2");
                                    });
                                } else {
                                    $timeout(function () {
                                        $('#printableTable').find('table').tableHeadFixer({'left': 1});
                                        $('#printableTable').find('table').find('#ui-sortable').find('th').css("z-index", "+=2");

                                    });
                                }
                            };

                            $scope.can = AclService.can;

                            $scope.pages = 20;

                            var rowIndex = false

                            var unregister = $scope.$watch('displayedCollection', function (t, p) {
                                  if (t.length > 0) {
                                    if (!rowIndex) {
                                        var index = 1;
                                        angular.forEach($scope.rowCollection, function (value) {
                                            value.index = index;
                                            index++;
                                        });
                                        rowIndex = true;
                                    }
                                    $scope.updateTable();
                                }
                            });

                            var getRef = function (item) {
                                var ref = {
                                    firebase: null
                                };
                                var business = Users.getCurrentBusiness(),
                                        userId = Users.getSessionUser().$id;
                                switch (item) {
                                    case 'default':
                                        ref.firebase = FirebaseRef.child('businesses/' + business).child('default/entities/' + $scope.section)
                                        break;
                                    case 'business':
                                        ref.firebase = FirebaseRef.child('businesses/' + business).child('eztables/' + $scope.section).child($scope.table);
                                        break;
                                    case 'group':
                                        break;
                                    case 'user':
                                        ref.firebase = FirebaseRef.child('users/' + userId).child('businesses/' + business).child('eztables/' + $scope.section).child($scope.table);
                                        break;
                                    default:
                                        break;
                                }
                                return ref;
                            }

                            var getColumns = function () {
                                if ($scope.$parent.tab.key === 'deadlines')
                                    $scope.$parent.tab.workingGroup = 'default';
                                var ref = getRef($scope.$parent.tab.workingGroup);
                                if (ref.firebase === null) {
                                    return false;
                                }
                                $scope.columns = $firebaseArray(ref.firebase.child('columns'));
                            }

                            $scope.$watch('table', function (t) {
                                if (!angular.isDefined(t)) {
                                    return false;
                                }
                                getColumns();
                            });

                            var getCellElements = function (element) {
                                return {
                                    row: element.row,
                                    col: element.col,
                                    title: element.col.title
                                };
                            }

                            $scope.openEntityModal = function (item, row) {
                                var modalInstance = $uibModal.open({
                                    templateUrl: 'partials/modals/keyModal.html',
                                    $scope: $scope,
                                    controller: 'RowKeyCtrl',
                                    size: 'lg',
                                    windowClass: 'row-detail',
                                    resolve: {
                                        currentRow: function () {
                                            return row;
                                        },
                                        currentItem: function () {
                                            return item;
                                        }
                                    }
                                });
                            }

                            $scope.openNotesModal = function (item, row) {
                              var modalInstance2 = $uibModal.open({
                                  templateUrl: 'partials/modals/notesModal.html',
                                  scope: $scope,
                                  controller: 'NotesCtrl',
                                  size: 'lg',
                                  windowClass: 'row-detail',
                                  resolve: {
                                      parent: function () {
                                          return row;
                                      },
                                      target: function () {
                                        return [item.key];
                                      },
                                      update: function () {
                                        return true;
                                      }
                                  }
                              });
                            }

                            //Table-related attributes/methods
                            $scope.options = [10, 20, 30, 'All'];

                            $scope.sortableOptions = {
                                placeholder: 'table-header',
                                helper: 'clone',
                                stop: function () {
                                    //Save order when the update is complete
                                    var order = 0;
                                    var row = $scope.rowCollection,
                                            search = $scope.search,
                                            section = $scope.section,
                                            groups = $scope.groups,
                                            table = $scope.table;

                                    angular.forEach($scope.columns, function (c) {
                                        c.order = order++;
                                        $scope.columns.$save(c);
                                    });
                                    $timeout(function () {
                                        $scope.search = search;
                                        $scope.section = section;
                                        $scope.groups = groups;
                                        $scope.table = table;
                                        $scope.rowCollection = row;

                                    });

                                }
                            }

                            var getRowOptions = function () {

                                var multiOptions = function () {
                                    var elements = [];
                                    var filter = _.filter($scope.columns, {type: 'entity'});

                                    angular.forEach(filter, function (f) {
                                        elements.push([
                                            f.title, function ($itemScope) {
                                                $itemScope.openEntityModal({
                                                    key: f.key,
                                                    title: f.title
                                                }, $itemScope.row);
                                            }
                                        ]);
                                    });
                                    return elements;
                                }

                                var options = [];

                                /*options.push(['Open Detail', function ($itemScope) {
                                 var elements = getCellElements($itemScope),
                                 row = elements.row;
                                 var modalInstance = $uibModal.open({
                                 templateUrl: 'partials/modals/rowDetailModal.html',
                                 $scope: $scope,
                                 controller: 'RowDetailCtrl',
                                 size: 'lg',
                                 windowClass: 'row-detail tabs',
                                 resolve: {
                                 currentRow: function () {
                                 return row;
                                 }
                                 }
                                 });
                                 }]);*/
                                if ($scope.isDeadline)
                                    return options;

                                options.push(['Delete Row', function ($itemScope) {
                                        var elements = getCellElements($itemScope);
                                        $itemScope.rowCollection.$remove(elements.row);
                                    }, function () {
                                        return $scope.can('edit');
                                    }]);

                                options.push([function ($itemScope) {
                                        var elements = getCellElements($itemScope);
                                        return 'Delete content from "' + elements.title + '"';
                                    }, function ($itemScope) {
                                        var elements = getCellElements($itemScope);
                                        elements.row[elements.col.key] = "";
                                        $itemScope.updateItem(elements.row);
                                    }, function ($itemScope) {
                                        var elements = getCellElements($itemScope);
                                        return angular.isDefined(elements.row[elements.col.key]) && $scope.can('edit');
                                    }]);

                                options.push(null);

                                options.push([function ($itemScope) {
                                        var elements = getCellElements($itemScope);
                                        return 'Add comments to: "' + elements.title + '"';
                                    }, function ($itemScope) {
                                        var modalInstance = $uibModal.open({
                                            templateUrl: 'partials/modals/cellComments.html',
                                            controller: 'CellCommentsCtrl',
                                            resolve: {
                                                title: function () {
                                                    return $itemScope.col.title;
                                                }
                                            }
                                        });
                                        modalInstance.result.then(function (comment) {
                                            var elements = getCellElements($itemScope);
                                            if (!angular.isDefined(elements.row.row_comments)) {
                                                elements.row.row_comments = {};
                                            }
                                            if (!angular.isDefined(elements.row.row_comments[elements.col.key])) {
                                                elements.row.row_comments[elements.col.key] = {
                                                    title: elements.title,
                                                    comments: []
                                                };
                                            }
                                            elements.row.row_comments[elements.col.key].comments.push(comment);
                                            $itemScope.updateItem(elements.row);
                                        }, function () { });
                                    }, function ($itemScope) {
                                        var elements = getCellElements($itemScope);
                                        return angular.isDefined(elements.row[elements.col.key]);
                                    }]);



                                var extras = multiOptions();

                                if (extras.length > 0) {
                                    options.push([
                                        'View...', function ($itemScope) {

                                        }, extras
                                    ]);
                                }

                                return options;
                            }

                            $scope.rowOptions = getRowOptions();

                            $scope.reassingVariables = function (vars) {
                              Object.keys(vars).forEach(function (v) {
                                $scope[v] = vars[v];
                              })
                            }

                            $scope.headerOptions = $scope.isDeadline ? [] : [
                                ['Add Column', function ($itemScope) {
                                  var scopeVars = {
                                    rowCollection: $scope.rowCollection,
                                    section: $scope.section,
                                    groups: $scope.groups,
                                    types: $scope.types,
                                    table: $scope.table,
                                    isDeadline: $scope.isDeadline,
                                    search: $scope.search
                                  }
                                  var table = angular.copy($scope.table);
                                        var col = $itemScope.col,
                                                modalInstance = $uibModal.open({
                                                    templateUrl: 'partials/modals/columnModal.html',
                                                    scope: $scope,
                                                    controller: 'ColumnCtrl',
                                                    resolve: {
                                                        columns: function () {
                                                            var ref = getRef('default'),
                                                                    columns = $firebaseArray(ref.firebase.child('columns'));
                                                            return columns.$loaded().then(function (cols) {
                                                                return _.filter(cols, function (col) {
                                                                    return col.type === 'number' || col.type === 'currency'
                                                                });
                                                            });
                                                        },
                                                        multiple: function () {
                                                            return false;
                                                        },
                                                        column: function () {
                                                            return null;
                                                        },
                                                        modifying: function () {
                                                          return false;
                                                        }
                                                    }
                                                });
                                        modalInstance.result.then(function (newColumn) {
                                            if (!newColumn) {
                                                return false;
                                            }
                                            var key = newColumn.db ? newColumn.$id: undefined;
                                            delete newColumn.$id;
                                            $timeout(function () {
                                                var order = col.order;
                                                newColumn.order = order;
                                                _.each($scope.columns, function (c) {
                                                    if (c.order >= order) {
                                                        c.order++;
                                                        $scope.columns.$save(c);
                                                    }
                                                });
                                                if (!newColumn.db) {
                                                    key = FirebaseRef.child('businesses/' + Users.getCurrentBusiness()).child('custom-columns/' + $scope.section).push(_.omit(newColumn, ['order', 'created'])).key;
                                                }
                                                var ref = FirebaseRef.child(['businesses', Users.getCurrentBusiness(), 'custom-columns', $scope.section, key, 'tables'].join('/'));
                                                ref
                                                  .once('value')
                                                  .then(function (snap) {
                                                    var columnTables = snap.val();
                                                    columnTables = columnTables || {};
                                                    columnTables[table] = true;
                                                    return ref.update(columnTables)
                                                  })
                                                  .then(function (w) {
                                                    toastr.success('Column ' + newColumn.title + ' successfully created')
                                                  })
                                                  .catch(console.log);
                                                $scope.columns.$add(_.omit(newColumn, ['db']))
                                                  .then(function () {
                                                    $scope.reassingVariables(scopeVars)
                                                  })
                                            });
                                        }, function () { });
                                    }, function ($itemScope) {
                                        return /*$scope.$parent.tab.workingGroup !== 'default' &&*/ $scope.can('edit');
                                    }],
                                ['Remove Column', function ($itemScope) {
                                        var scopeVars = {
                                          rowCollection: $scope.rowCollection,
                                          section: $scope.section,
                                          groups: $scope.groups,
                                          types: $scope.types,
                                          table: $scope.table,
                                          isDeadline: $scope.isDeadline,
                                          search: $scope.search
                                        }
                                        var table = angular.copy($scope.table);
                                        $scope.columns.$remove($itemScope.col);
                                        $firebaseArray(FirebaseRef.child(['businesses', Users.getCurrentBusiness(), 'custom-columns', $scope.section].join('/')))
                                          .$loaded()
                                          .then(function (c) {
                                            var thisCol = _.find(c, function (o) {
                                              return o.key === $itemScope.col.key;
                                            })
                                            // if (!snap.val()) return;
                                            // var columnTables = snap.val();
                                            //console.log(c.$indexFor(thisCol.$id), thisCol, thisCol.$id);
                                            var position = c.$indexFor(thisCol.$id);
                                            c[position].tables[table] = false;
                                            c.$save(position)
                                              .then(function (w) {
                                                toastr.success('Column removed')
                                                $scope.reassingVariables(scopeVars)
                                              })
                                          })
                                    }, function ($itemScope) {
                                        return $itemScope.col.created && $scope.can('edit');
                                    }],
                                    [function ($itemScope) {
                                        var elements = getCellElements($itemScope);
                                        return 'Toggle "' + elements.title + '" deadline';
                                    }, function ($itemScope) {
                                        var scopeVars = {
                                          rowCollection: $scope.rowCollection,
                                          section: $scope.section,
                                          groups: $scope.groups,
                                          types: $scope.types,
                                          table: $scope.table,
                                          isDeadline: $scope.isDeadline,
                                          search: $scope.search
                                        }
                                        var elements = getCellElements($itemScope);
                                        return $itemScope.toggleDeadline(elements, $scope.section);
                                    }, function ($itemScope) {
                                        var elements = getCellElements($itemScope);
                                        return $scope.can('edit') && elements.col.type !== 'entity' && elements.col.type !== 'notes' && elements.col.type !== 'select'
                                }],
                                null, //divider
                                ['Modify Column', function ($itemScope) {
                                        var scopeVars = {
                                          rowCollection: $scope.rowCollection,
                                          section: $scope.section,
                                          groups: $scope.groups,
                                          types: $scope.types,
                                          table: $scope.table,
                                          isDeadline: $scope.isDeadline,
                                          search: $scope.search
                                        }
                                        var table = $scope.table;
                                        var modalInstance = $uibModal.open({
                                            templateUrl: 'partials/modals/columnModal.html',
                                            scope: $scope,
                                            controller: 'ColumnCtrl',
                                            resolve: {
                                                columns: function () {
                                                    var ref = getRef('default'),
                                                            columns = $firebaseArray(ref.firebase.child('columns'));
                                                    return columns.$loaded().then(function (cols) {
                                                        return _.filter(cols, function (col) {
                                                            return col.type === 'number' || col.type === 'currency'
                                                        });
                                                    });
                                                },
                                                multiple: function () {
                                                    return false;
                                                },
                                                column: function () {
                                                    return $itemScope.col;
                                                },
                                                modifying: function () {
                                                  return true;
                                                }
                                            }
                                        });
                                        modalInstance.result.then(function (newColumn) {
                                            if (!newColumn) {
                                                return false;
                                            }
                                            $timeout(function () {
                                                var customCols = $firebaseArray(FirebaseRef.child('businesses/' + Users.getCurrentBusiness()).child('custom-columns/' + $scope.section));
                                                customCols.$loaded().then(function (c) {
                                                    var obj = _.find(c, function (c) {
                                                        return c.key === $itemScope.col.key;
                                                    });
                                                    var tables = obj.tables;
                                                    var key = c.$keyAt(obj);
                                                    var record = c.$indexFor(key);
                                                    c[record] = _.omit(newColumn, ['created', 'order']);
                                                    c[record].tables = tables;
                                                    c.$save(record).then(function () {
                                                      $scope.reassingVariables(scopeVars)
                                                    })
                                                    var existsInTables = Object.keys(tables)
                                                      .filter(t => tables[t])
                                                    var asyncs = []
                                                    for (var i = 0; i < existsInTables.length; i++) {
                                                      var innerRef;
                                                      if (existsInTables[i] === 'default') {
                                                         innerRef = 'default/entities/' + $scope.section + '/columns';
                                                      } else {
                                                        innerRef = 'eztables/' + $scope.section + '/' + table + '/columns';
                                                      }
                                                      asyncs.push(FirebaseRef.child('businesses/' + Users.getCurrentBusiness()).child(innerRef).child(newColumn.$id).update(_.omit(newColumn, ['$id', 'created', 'order', '$priority'])))
                                                    }
                                                    Promise.all(asyncs).then(function () {
                                                      $scope.reassingVariables(scopeVars)
                                                    })
                                                })
                                                //
                                                // var ref = FirebaseRef.child(['businesses', Users.getCurrentBusiness(), 'custom-columns', $scope.section, 'tables'].join('/'))
                                                // ref
                                                //   .once('value')
                                                //   .then(function (snap) {
                                                //     if (!snap.val()) { console.log('no existe'); return; }
                                                //     var tables = snap.val();
                                                //     console.log('tables', tables);
                                                //     var existsInTables = Object.keys(tables)
                                                //       .filter(t => tables[t])
                                                //     console.log(existsInTables);
                                                //   }
                                                // $scope.columns.$add(_.omit(newColumn, ['db']));
                                            });
                                        }, function () { });
                                    }, function ($itemScope) {
                                        return $itemScope.col.created && $scope.can('edit');
                                    }]
                            ];
                            $scope.addToTable = function () {
                                $scope.rowCollection.$add({
                                    created_at: moment().format()
                                }).then(function () {
                                    $('.tab-pane.active .smart-table tbody tr.table-row').last().find('input').first().focus();
                                    $scope.updateTable();
                                });
                            }

                            $scope.removeRecord = function (row) {
                                $scope.rowCollection.$remove(row).then(function () {
                                    toastr.error("The record was removed successfully");
                                });
                            }

                            $scope.toggleDeadline = function (elements, section) {
                                Object.keys(elements.col).forEach(k => {
                                    if (k.charAt(0) === '$')
                                        delete elements.col[k];
                                })
                                var ref = FirebaseRef.child('businesses/' + Users.getCurrentBusiness() + '/default/entities/deadlines/tables/' + section);
                                ref.once('value').then(function (deadLinesEntity) {
                                    var dlinesColumns = deadLinesEntity.val().columns;
                                    var index;
                                    var newArr = dlinesColumns.filter(function (col, i) {
                                        if (col.key === elements.col.key)
                                            index = i;
                                        return col.key === elements.col.key;
                                    });
                                    newArr.length ? dlinesColumns.splice(index, 1) : dlinesColumns.push(elements.col);
                                    var newData = {columns: dlinesColumns};
                                    ref.update(newData);

                                    // if (angular.isDefined(elements.row.row_deadlines)) {
                                    // 	elements.row.row_deadlines[elements.col.key] = {active: false, date: false}
                                    // } else {
                                    // 	elements.row.row_deadlines = {}
                                    // 	elements.row.row_deadlines[elements.col.key] = {active: true, date: (elements.col.type === 'date' ? elements.row[elements.col.type] : false)}
                                    // }
                                    // $scope.rowCollection.$save(elements.row)
                                    // console.log('rowel', elements.row);
                                });
                            }

                            $scope.updateItem = function (row, callback = undefined, dateObject = undefined) {
                                if (document.getElementById('toast-container') !== null) {
                                    toastr.clear();
                                }
                                if ((row.status === 'Acquired' || row.status === 'Acquired') && $scope.section === 'deal') {
                                    var newRow = Object.keys(row).reduce(function (nr, k) {
                                        if (k.charAt(0) !== '$')
                                            nr[k] = row[k];
                                        return nr;
                                    }, {})
                                    newRow.created_at = moment().format();
                                    var newRefKey = FirebaseRef.child('businesses/' + Users.getCurrentBusiness() + '/information/property').push().key;
                                    FirebaseRef.child('businesses/' + Users.getCurrentBusiness() + '/information/property/' + newRefKey)
                                            .update(newRow)
                                            .then(function (s) {
                                                $scope.rowCollection.$remove(row);
                                                toastr.success("Deal transferred to properties");
                                            });
                                    return
                                }
                                if (row.status !== 'Sold' && row.status !== 'Inactive' && row.status !== 'Acquired' && row.status !== 'Sold' && row.status && $scope.section === 'property') {
                                    var newRow = Object.keys(row).reduce(function (nr, k) {
                                        if (k.charAt(0) !== '$')
                                            nr[k] = row[k];
                                        return nr;
                                    }, {})
                                    newRow.created_at = moment().format();
                                    var newRefKey = FirebaseRef.child('businesses/' + Users.getCurrentBusiness() + '/information/deal').push().key;
                                    FirebaseRef.child('businesses/' + Users.getCurrentBusiness() + '/information/deal/' + newRefKey)
                                            .update(newRow)
                                            .then(function (s) {
                                                $scope.rowCollection.$remove(row);
                                                toastr.success("Property transferred to deals");
                                            });
                                    return
                                }
                                if (dateObject) {
                                    FirebaseRef.child('businesses/' + Users.getCurrentBusiness() + '/information/deadlines').once("value")
                                            .then(function (deadlinesObj) {
                                                var deadlines = deadlinesObj.val();
                                                angular.forEach(deadlines, function (value, key) {
                                                    if (value.storenumber == dateObject.row.storenumber && value.fromcolumn == dateObject.col.title) {
                                                        return $scope.toggleDeadline(dateObject);
                                                    }
                                                });
                                            });
                                }
                                /* ---- UPDATE FIREBASE TABLE ---- */
                                // $scope.$parent.tabset.tabs.forEach(function(t) {
                                // 	if(row[t.key]) delete row[t.key];
                                // })
                                $scope.rowCollection.$save(row).then(function (ref) {
                                    if (callback)
                                        callback(ref.key);
                                    toastr.success("The record was saved successfully.");
                                    // if(format) $scope.formatEntry(format, value);
                                });
                            }

                            $scope.entityHasContent = function (object) {
                                var keys = _.keys(object),
                                        count = 1;
                                for (var key in object) {
                                    if (_.isEmpty(object[key])) {
                                        count++;
                                    }
                                }
                                return count === keys.length;
                            };

                            $scope.solveFormula = function (col,  row) {
                                var res;
                                try {
                                    res = math.eval(col.formula, row);
                                    row[col.key] = res;
                                    if (col.isCurrency) {
                                        res = accounting.formatMoney(res);
                                    }
                                } catch (err) {
                                    res = col.title;
                                }
                                return res;
                            };



                            $scope.formatEntry = function (format, value) {
                                return value
                            }

                            $scope.showInTooltip = function (data, title, col) {
                              var result = col.type === 'formula' ? col.hint : (data || title)

                              if (['notes', 'entity', 'file', 'checklist'].some(e => e === col.type)) {
                                return title
                              }
                              return result
                            }

                        }
                    }
                }
            }]);
