var DEFAULT_TABLES = {
  entities: {
    property: {
      title: 'Property',
      key: 'property',
      table: true,
      columns: [
        {key: 'rentpercentage', title: 'Rent %', type: 'currency'},
        {key: 'noticedue', title: 'Notice Due', type: 'date'},
        {key: 'notesfromtenant', title: 'Notes from Tenant', type: 'notes'},
        {key: 'propertyinoptionperiod', title: 'Property in Option Period', type: 'select', options: ['Yes', 'No']},
        {key: 'dateacquiredsold', title: 'Date Acquired/Sold', type: 'date'},
        {key: 'owner', title: 'Owner', type: 'entity'},
        {key: 'roofsystem', title: 'Roof System', type: 'text'},
        {key: 'storecontactnumber', title: 'Store Contact Number', type: 'telephone'},
        {key: 'storagemanageremail', title: 'Storage Manager Email', type: 'email'},
        {key: 'prospect', title: 'Prospect', type: 'entity'},
        {key: 'maintenance', title: 'Maintenance', type: 'text'},
        {key: 'duediligence', title: 'Due Diligence', type: 'entity'},
        {key: 'finance', title: 'Finance', type: 'entity'}
      ]
    },
    cam: {
      title: 'CAM',
      key: 'cam',
      table: false,
      columns: [
        {key: 'services', title: 'Services', type: 'text'},
        {key: 'vendor', title: 'Vendor', type: 'text'},
        {key: 'contactdetails', title: 'Contact Details', type: 'telephone'},
        {key: 'vendorpayment', title: 'Vendor Payment', type: 'currency'},
        {key: 'camreimbursement', title: 'CAM Reimbursement', type: 'text'},
        {key: 'adminfeepercentage', title: 'Admin Fee %', type: 'number'}
      ]
    },
    leaseresponsibilities: {
      title: 'Lease Responsibilities',
      key: 'leaseresponsibilities',
      table: false,
      columns: [
        {key: 'leasedate', title: 'Lease Date', type: 'date'},
        {key: 'terminationdate', title: 'Termination Date', type: 'date'},
        {key: 'hazardmaterialandsustances', title: 'Hazard Material & Sustances', type: 'text'},
        {key: 'exteriormaintenancebstructure', title: 'Exterior Maintenance B(Structure)', type: 'text'},
        {key: 'abatement', title: 'Abatement', type: 'select', options: ['Yes', 'No']},
        {key: 'casualty', title: 'Casualty', type: 'text'},
        {key: 'firealarminstallation', title: 'Fire Alarm Installation', type: 'text'},
        {key: 'firealarmmonitoring', title: 'Fire Alarm Monitoring', type: 'text'},
        {key: 'spirnklersystem', title: 'Spirnkler System', type: 'text'}
      ]
    },
		tickets: {
		  title: 'Tickets',
		  key: 'tickets',
		  table: true,
		  columns: [
		    {key: 'storenumber', title: 'Store Number', type: 'text'},
		    {key: 'type', title: 'Type', type: 'select', options: ['Capital Improvement Ticket', 'Roof Warranty Extension Ticket', 'Misc Ticket']},
		    { key: 'prospect', title: 'Prospect', type: 'entity' }, //this
		    {key: 'contactnumber', title: 'Contact #', type: 'telephone'},
		    {key: 'storecontactnumber', title: 'Store Contact Number', type: 'telephone'},
		    {key: 'storemanageremail', title: 'Store Manager Email', type: 'email'},
		    {key: 'owner', title: 'Owner', type: 'text'},
		    {key: 'warranty', title: 'Warranty', type: 'select', options: ['Yes', 'No'] },
		    { key: 'number', title: 'Ticket Number', type: 'text' },
		    {key: 'ticketreceived', title: 'Ticket Received', type: 'date'},
		    { key: 'issue', title: 'Issue', type: 'text' },
		    {key: 'contractorname', title: 'Contractor Name', type: 'text'},
		    {key: 'contractorphone', title: 'Contractor Phone', type: 'telephone'},
		    { key: 'expectedstartdate', title: 'Expected Start Date', type: 'date' },
		    { key: 'expectedcompletiondate', title: 'Expected Completion Date', type: 'date' },
		    {key: 'status', title: 'Status', type: 'select', options: ['Open', 'Closed']},
		    { key: 'notes', title: 'Notes', type: 'notes' },
		  ]
		},
    owner: {
      title: "Owner",
      key: 'owner',
      table: true,
      columns: [
        {key: 'id', title: 'ID', type: 'text'},
        {key: 'storenumber', title: 'Store Number', type: 'number'},
        { key: 'ownername', title: 'Owner', type: 'text' },
        { key: 'client1', title: 'Clent', type: 'text'},
        {key: 'percentage1', title: '% client ownership', type: 'number'},
        { key: 'client2', title: 'Clent', type: 'text'},
        {key: 'percentage2', title: '% client ownership', type: 'number'},
        { key: 'client3', title: 'Clent', type: 'text'},
        {key: 'percentage3', title: '% client ownership', type: 'number'},
      ]
    },
    finance: {
      title: 'Finance',
      key: 'finance',
      table: true,
      columns: [
        {key: 'id', title: 'ID', type: 'text'},
        {key: 'storenumber', title: 'Store Number', type: 'number'},
        {key: 'policyexpirationdate', title: 'Policy Expiration Date', type: 'date'},
        {key: 'businessincome', title: 'Business Income', type: 'select', options: ['Yes', 'No']},
        {key: 'generalliability', title: 'Generalliability', type: 'select', options: ['Yes', 'No']},
        {key: 'umbrella', title: 'Umbrella', type: 'select', options: ['Yes', 'No']},
        {key: 'flood', title: 'Flood', type: 'select', options: ['Yes', 'No']},
        {key: 'costsegregationpaidatclosing', title: 'Cost Segregation Paid at Closing', type: 'select', options: ['Yes', 'No']},
        {key: 'costsegregationreportdate', title: 'Cost Segregation Report Date', type: 'date'},
        {key: 'costsegregationrequestdate', title: 'Cost Segregation Request Date', type: 'date'},
        {key: 'tenantpaystaxes', title: 'Tenant Pays Taxes', type: 'select', options: ['Yes', 'No']},
        {key: 'clientpaystaxes', title: 'Client Pays Taxes', type: 'select', options: ['Yes', 'No']},
        {key: 'annualreport', title: 'Annual Report', type: 'date'},
        {key: 'mortgagetype', title: 'Mortgage Type', type: 'select', options: ['Fixed', 'Variable']},
        {key: 'mortgageyears', title: 'Mortgage Years', type: 'number'},
        {key: 'mortgageinterestrate', title: 'Mortgage Interest Rate', type: 'number'},
        {key: 'mortgageexpirationdate', title: 'Mortgage Expiration Date', type: 'date'},
        {key: 'mortgagemonthlypayment', title: 'Mortgage Monthly Payment', type: 'currency'},
        {key: 'mortgagepaymentduedate', title: 'Mortgage Payment Due Date', type: 'date'},
        {key: 'mortgageswap', title: 'Mortgage swap', type: 'select', options: ['Yes', 'No']}
      ]
    },
    prospect: {
      title: 'Prospective Client',
      key: 'prospect',
      table: true,
      columns: [
        {key: 'name', title: 'Name', type: 'text'},
        {key: 'phone', title: 'Phone', type: 'telephone'},
        {key: 'email', title: 'Email', type: 'email'},
        {key: 'referralsource', title: 'Referral Source', type: 'text'},
        {key: 'referralsourcephone', title: 'Referral Source Phone', type: 'text'},
        {key: 'referralsourceemail', title: 'Referral Source Email', type: 'text'},
        {key: 'tasksnotes', title: 'Tasks/Notes', type: 'notes'},
        {key: 'clientprospectdesignation', title: 'Client/Prospect Designation', type: 'text'},
        {key: 'clientrank', title: 'Client Rank', type: 'number'}
      ]
    },
    duediligence: {
      title: "Due Diligence",
			key: 'duediligence',
      table: false,
			columns: [
        {key: 'type', title: 'Type', type: 'text'},
        {key: 'duediligenceexpirationdate', title: 'Due Diligence Expiration Date', type: 'date'},
        { key: 'bid', title: 'BID', type: 'text'},
        {key: 'esadue', title: 'ESA Due', type: 'text'},
        {key: 'rcvd', title: 'RCVD', type: 'text'},
        {key: 'pcadue', title: 'PCA Due', type: 'date'},
        {key: 'cfd', title: 'CFD', type: 'select', options: ['Yes', 'No']},
        {key: 'altavendor', title: 'Alta Vendor', type: 'text'},
        {key: 'pre', title: 'PRE', type: 'select', options: ['Yes', 'No']},
        {key: 'fnl', title: 'FNL', type: 'select', options: ['Yes', 'No']},
        {key: 'appraisal', title: 'Appraisal', type: 'text'},
        { key: 'datedue', title: 'Date Due', type: 'date' },
        {key: 'received', title: 'Received', type: 'text'},
        { key: 'cost', title: 'Cost', type: 'currency'},
        { key: 'sitevisitdate', title: 'Site Visit (Date)', type: 'date' }
			]
    },
    deal: {
      title: 'Deal',
      key: 'deal',
      table: true,
      columns: [
        {key: 'landlordresponsibilities', title: 'Landlord Responsibilities', type: 'text'},
        {key: 'extensionwithcvswag', title: 'Extension with CVS/WAG', type: 'select', options: ['Yes', 'No']},
        {key: 'effectivedateofpsa', title: 'Effective Date of PSA', type: 'date'},
        {key: 'amendmentstopsa', title: 'Amendments to PSA', type: 'select', options: ['Yes', 'No']},
        {key: 'buyingentity', title: 'Buying Entity', type: 'text'},
        {key: 'buyer', title: 'Buyer', type: 'text'},
        {key: 'earnestmoney', title: 'Earnest Money', type: 'text'},
        {key: 'additionaldeposit', title: 'Additional Deposit', type: 'text'},
        {key: 'inspectionperiod', title: 'Inspection Period', type: 'text'},
        {key: 'dateofrofrrequestreceived', title: 'Date of Request/Received', type: 'text'},
        {key: 'snda', title: 'SNDA', type: 'select', options:['Yes', 'No']},
        {key: 'dateofestoppelsrequestreceived', title: 'Date of Estoppels Request/Received', type: 'text'},
        {key: 'duediligence', title: 'Due Diligence', type: 'entity'},
        {key: 'closingdaysafterinspectionperiod', title: 'Closing (Days After Inspection Period)', type: 'text'},
        {key: 'closingdate', title: 'Closing Date', type: 'date'},
        {key: 'deckcreated', title: 'Deck Created', type: 'text'},
        {key: 'titlecompany', title: 'Title Company', type: 'text'},
        {key: 'sitevisitdate', title: 'Site Visit (Date)', type: 'date'},
        {key: 'checkdotplans', title: 'Check Dot Plans', type: 'date'},
        {key: 'femafloodmap', title: 'FEMA Flood Map', type: 'text'},
        {key: 'reviewofleasebyl2', title: 'Review of Lease by L2', type: 'text'},
        {key: 'reviewofamendmentsbyl2', title: 'Review of Amendments by L2 (Post Estoppel)', type: 'text'},
        {key: 'reviewofeasementagreementbyl2', title: 'Review of Easement Agreement by L2', type: 'text'},
        {key: 'phasei', title: 'Phase I (ESA)', type: 'date'},
        {key: 'reviewofphaseibyl2', title: 'Review of Phase I by L2', type: 'text'},
        {key: 'propertyinvestigationreport', title: 'Property Investigation Report (PCA)', type: 'date'},
        {key: 'reviewofpcabyl2', title: 'Review of PCA by L2', type: 'text'},
        {key: 'appraisals', title: 'Appraisals', type: 'text'},
        {key: 'reviewofappraisalsbyl2', title: 'Review of Appraisal by L2', type: 'text'},
        {key: 'reviewoftitlecommitmentbyl2', title: 'Review of Title Commitment by L2', type: 'text'},
        {key: 'altasurvey', title: 'Alta Survey', type: 'date'},
        {key: 'reviewofsurveybyl2', title: 'Review of Survey by L2', type: 'text'},
        {key: 'maintenancecreditnotes', title: 'Maintenance Credit/Notes', type: 'text'},
        {key: 'optionterm', title: 'Option Term', type: 'text'},
        {key: 'noticedateforoptionterm', title: 'Notice Date for Option Term', type: 'date'},
        {key: 'optionrents', title: 'Option Rents', type: 'currency'}
      ]
    },
    roofwarrantyextension: {
      title: 'Roof Warranty-Extension',
      key: 'roofwarrantyextension',
      table: false,
      columns: [
        {key: 'address', title: 'Address', type: 'text'},
        {key: 'storemgr', title: 'Store MGR', type: 'text'},
        {key: 'storecontactnumber', title: 'Store Contact Number', type: 'telephone'},
        {key: 'tickets', title: 'Tickets', type: 'entity'},
        {key: 'provider', title: 'Provider', type: 'entity'}
      ]
    },
    capitalimprovements: {
      title: 'Capital improvements',
      key: 'capitalimprovements',
      table: false,
      columns: [
        {key: 'address', title: 'Address', type: 'text'},
        {key: 'storemgr', title: 'Store MGR', type: 'text'},
        {key: 'storecontactnumber', title: 'Store Contact Number', type: 'telephone'},
        {key: 'warranty', title: 'Warranty', type: 'text'},
        {key: 'tickets', title: 'Tickets', type: 'entity'},
        {key: 'owner', title: 'Owner', type: 'entity'}
      ]
    },
    reimbursements: {
      title: 'Reimbursements',
      key: 'reimbursements',
      table: false,
      columns: [
        {key: 'address', title: 'Address', type: 'text'},
        {key: 'reason', title: 'Reason', type: 'text'},
        {key: 'submissiondate', title: 'Submission Date', type: 'date'},
        {key: 'amount', title: 'Amount', type: 'number'},
        {key: 'responsefromcorp', title: 'Response From Corp', type: 'text'},
        {key: 'taxduedate', title: 'Tax Due Date', type: 'date'}
      ]
    },
    divesttracking: {
      title: 'Divest Tracking',
      key: 'divesttracking',
      table: false,
      columns: [
        {key: 'address', title: 'Address', type: 'text'},
        {key: 'citystate', title: 'City, State', type: 'text'},
        {key: 'saleprice', title: 'Sale Price', type: 'text'},
        {key: 'rent', title: 'Rent', type: 'currency'},
        {key: 'listingbroker', title: 'Listing Broker', type: 'text'},
        {key: 'caprate', title: 'Cap Rate', type: 'formula', formula: 'rent/purchaseprice', isCurrency: true, hint: '"Rent"/"Purchase Price"'},
        {key: 'termexpiration', title: 'Term Expiration', type: 'date'},
        {key: 'effectivedateofpsa', title: 'Effective Date of PSA', type: 'date'},
        {key: 'earnestmoney', title: 'Earnest Money', type: 'text'},
        {key: 'dddeliverydate', title: 'DD Delivery Date', type: 'date'},
        {key: 'ddsenttoseller', title: 'DD Sent to Seller', type: 'text'},
        {key: 'dateofrofrrequestreceived', title: 'Date of Rof Request/Received', type: 'date'},
        {key: 'snda', title: 'SNDA', type: 'select', options: ['Yes', 'No']},
        {key: 'dateofestoppelsrequestreceived', title: 'Date of Stoppel Request/Received', type: 'date'},
        {key: 'duediligenceperiod', title: 'Due Diligence Period', type: 'text'},
        {key: 'duediligenceexpirationdate', title: 'Due Diligence Expiration Date', type: 'date'},
        {key: 'closingdaysafterinspectionperiod', title: 'Closing (Days After Inpection Period)', type: 'text'},
        {key: 'closingdate', title: 'Closing date', type: 'date'}
      ]
    },
    provider: {
      title: 'Provider',
      key: 'provider',
      table: false,
      columns: [
        {key: 'providername', title: 'Provider Name', type: 'text'},
        {key: 'providernumber', title: 'Provider Number', type: 'telephone'}
      ]
    },
    deadlines: {
      title: 'Deadlines',
      key: 'deadlines',
      table: true,
      tables: {
        deal: {
          key: 'deal',
          title: 'Deal',
          columns: [
            {key: 'storenumber', title: 'Store Number', type: 'number'}
          ]
        },
        property: {
          key: 'property',
          title: 'Property',
          columns: [
            {key: 'storenumber', title: 'Store Number', type: 'number'}
          ]
        },
        finance: { key: 'finance',
          title: 'Finance',
          columns: [
            {key: 'storenumber', title: 'Store Number', type: 'number'}
          ]
        },
        owner: {
          key: 'owner',
          title: 'Owner',
          columns: [
            {key: 'storenumber', title: 'Store Number', type: 'number'}
          ]
        },
        prospect: {
          key: 'prospect',
          title: 'Prospect',
          columns: [
            {key: 'name', title: 'Name', type: 'text'}
          ]
        },
        tickets: {
          key: 'tickets',
          title: 'Tickets',
          columns: [
            {key: 'storenumber', title: 'Store Number', type: 'number'}
          ]
        }
      }
    }
  }
};


var COMMON_COLUMNS = [
  {key: 'id', title: 'ID', type: 'text'},
  {key: 'storenumber', title: 'Store number', type: 'text'},
  {key: 'status', title: 'Status', type: 'select', options: ['Under Contract w/ Buyer', 'Under Contract w/o Buyer', 'Negotiating Contract', 'Acquired', 'Sold', 'Inactive', 'L2 Purchasing']},
  {key: 'address', title: 'Address', type: 'text'},
  {key: 'citystate', title: 'City, State', type: 'text'},
  {key: 'propertyindicator', title: 'Property indicator', type: 'select', options: ['Properties with Management Fee', 'Properties with No Management Fees', 'Properties with No Management Fees (but that have property management)']},
  {key: 'purchaseprice', title: 'Purchase Price', type: 'currency'},
  {key: 'rent', title: 'Rent', type: 'currency'},
  {key: 'caprate', title: 'Cap Rate', type: 'formula', formula: 'rent/purchaseprice', isCurrency: true, hint: '"Rent"/"Purchase Price"'}, // Check formula equation
  {key: 'termexpiration', title: 'Term Expiration', type: 'date'},
  {key: 'nnornnn', title: 'NN or NNN', type: 'select', options: ['NN', 'NNN']},
  {key: 'sqft', title: 'SQFT', type:'number'},
  {key: 'numberofoptions', title: '# of Options', type: 'number'}, // Check if should be '#' insted of 'number'
  {key: 'optionperiodyears', title: 'Option Period Years', type: 'number'},
  {key: 'increases', title: 'Increases', type: 'select', options: ['Yes', 'No']},
  {key: 'lender', title: 'Lender', type: 'text'},
  {key: 'rate', title: 'Rate', type: 'text'},
  {key: 'type', title: 'Type', type: 'text'},
  {key: 'amortization', title: 'Amortization', type: 'text'},
  {key: 'duedate', title: 'Due Date', type: 'text'},
  {key: 'reportedsales', title: 'Reported Sales', type: 'text'},
  {key: 'cam', title: 'CAM', type: 'entity'}, // Check because xls shows entity in one and text in other
  {key: 'leaseresponsibilities', title: 'Lease Responsibilities', type: 'entity'},
  {key: 'roofextensionwarranty', title: 'Roof Extension Warranty', type: 'text'},
  {key: 'l2fee', title: 'L2 Fee', type: 'currency'},
  {key: 'managementpercentage', title: 'Management %', type: 'number'},
  {key: 'monthlymanagementfee', title: 'Monthly Management Fee', type: 'currency'},
  {key: 'warranty', title: 'Warranty', type: 'text'},
  {key: 'tickets', title: 'Tickets', type: 'entity'},
  {key: 'taxes', title: 'Taxes', type: 'number'},
  {key: 'onwer', title: 'Owner', type: 'entity'},
  {key: 'foreignllc', title: 'Foreign LLC', type: 'text'},
  {key: 'gmei', title: 'GMEI', type: 'text'},
  {key: 'foreignagentrenewall', title: 'Foreign Agent Renewall', type: 'text'},
  {key: 'msa', title: 'MSA', type: 'text'},
  {key: 'parkinglotrequirements', title: 'Parking Lot Requirements', type: 'select', options: ['Yes', 'No']},
  {key: 'rentholidaymonths', title: 'Rent Holiday Months', type: 'number'},
  {key: 'rentholidayamount', title: 'Rent Holiday Amount', type: 'currency'}
];

/**
 * Entities to show as tables have a flag (true)
 * In this case the information for those has to be saved
 * as a rowCollection, and the reference has to be saved in the parent */

var AddBusinessCtrl = function ($scope, FirebaseRef, $firebaseArray, Users, $uibModalInstance) {

	var _businessId = null;

	$scope.saveBusiness = function (isValid) {
		if (!isValid) {
			return false;
		}
		var businessRef = FirebaseRef.child("businesses"),
			list = $firebaseArray(businessRef),
			date = moment().format();

		COMMON_COLUMNS.reverse().forEach((col) => {
			DEFAULT_TABLES.entities['property'].columns.unshift(col);
			DEFAULT_TABLES.entities['deal'].columns.unshift(col);
		});

		list.$add({
			"created_at": date,
			"name": $scope.newBusiness.name,
			"default": DEFAULT_TABLES
		}).then(function (ref) {
			_businessId = ref.key
			Users.getCurrentUser().$loaded().then(function (data) {
				if (!angular.isDefined(data['businesses'])) {
					data['businesses'] = {};
				}
				data['businesses'][_businessId] = {
					created_at: date
				};
				data['currentBusiness'] = _businessId;
				data.$save().then(function () {
					$uibModalInstance.close(true);
				});
			});
		});
	}
}

AddBusinessCtrl.$inject = ['$scope', 'FirebaseRef', '$firebaseArray', 'Users', '$uibModalInstance'];

angular.module('ezTable.controllers')

	.controller('AddBusinessCtrl', AddBusinessCtrl);
