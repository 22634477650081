
angular.module('ezTable.controllers')

.controller('EntityCtrl', ['$scope', 'Users', 'FirebaseRef', '$firebaseObject', function($scope, Users, FirebaseRef, $firebaseObject){

    $scope.$watch('entity', function (e) {
        var business = Users.getCurrentBusiness(),
            businessRef = FirebaseRef.child('businesses/' + business),
            entityRef = businessRef.child('default/entities').child(e);
        $scope.element = $firebaseObject(entityRef);
    });

}]);