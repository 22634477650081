angular.module('ezTable.directives')

        .directive('smartCell', ['$http', '$compile', '$templateCache', 'Templates', 'Users', 'FireUpload', '$filter', function ($http, $compile, $templateCache, Templates, Users, FireUpload, $filter) {
                return {
                    restrict: 'E',
                    replace: true,
                    require: '^smartTable',
                    template: '<span></span>',
                    scope: {
                      row: '=',
                      col: '=',
                      updateItem: '='
                    },
                    link: function (scope, element, attrs, smart) {
                        scope.checkNumber = function () {
                          return {
                            original: scope.row[scope.col.key],
                            decorated: $filter('setFormat')(scope.row[scope.col.key], scope.col)
                          }
                        }
                        var template = $compile($templateCache.get(scope.col.type+'-cell.html'))(scope);

                        if (scope.$parent.can('edit')) {
                          element.html(template)
                        } else {
                          element.html(scope.row[scope.col.key] || scope.col.title)
                        }

                        scope.files = [];

                        scope.save = function (data) {
                          scope.row[scope.col.key] = data;
                          scope.$parent.updateItem(scope.row);
                        };

                        var files = [];
                        scope.uploadFile = function () {
                          angular.forEach(scope.files, function(file){
                            FireUpload.upload(Users.getCurrentBusiness() + '/files', file).then(function(url){
                              if(!angular.isDefined(scope.row[scope.col.key]) || scope.row[scope.col.key] == ""){
                                scope.row[scope.col.key] = {
                                  files: []
                                }
                              }
                              if (!scope.row[scope.col.key].files) scope.row[scope.col.key].files = []
                              scope.row[scope.col.key].files.push({
                                name: file.name,
                                ext: file.name.split('.').pop(),
                                url: url
                              });
                              scope.$parent.updateItem(scope.row);
                            }).catch(function (message) {
                              toastr.error('There was an error uploading ' + file.name);
                            });
                            scope.files = [];
                          });
                        };

                        scope.displayCurrency = function (data) {
                          return accounting.formatMoney(data);
                        };

                        scope.verifyNumber = function (data) {
                          var number = parseFloat(data);
                          if (number) scope.save(data);
                        }

                        scope.saveDate = function (data) {
                          scope.row[scope.col.key] = data;
                          scope.$parent.updateItem(scope.row, void 0, { row: scope.row, col: scope.col });
                        }

                        scope.showForMultiple = function (data, title) {
                          return /^\s+$/.test(data) || !data ? title : data;
                        }
                    }

                };
            }]);
