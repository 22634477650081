angular.module('ezTable.services')

.factory('Mailer', ['$firebaseArray', '$firebaseObject', 'FirebaseRef', '$http', function ($firebaseArray, $firebaseObject, FirebaseRef, $http) {
  return {
    varifyUserExistence: function (email) {
      return FirebaseRef.child('users').once('value').then(function (usersRef) {
        var users = usersRef.val();
        var exists = Object.keys(users).filter(function (u) {
          return users[u].email === email;
        });
        return exists.length;
      });
    },
    // sendInvitation: function (params) {
    //
    //
    // 		var createdData = {};
    // 		var currentUid = $scope.currentUser.$id;
    // 		var createdPsswd;
    // 		var tokenCreated;
    // 		var email = $scope.colleague.email.replace(/\./g, ',');
    // 		var business = $scope.colleague.business;
    // 		var role = $scope.colleague.role.val;
    // 		var businessesNames = $scope.businessesNames;
    // 		var currentBusiness;
    // 		var name = $scope.colleague.name;
    // 		createdPsswd = token();
    // 		var config = {
    //         apiKey: "AIzaSyBT0J4nnPxcppjP68NozgyGajiglYQJvbw",
    //         authDomain: "ez-table-80d9c.firebaseapp.com",
    //         databaseURL: "https://ez-table-80d9c.firebaseio.com",
    //         storageBucket: "ez-table-80d9c.appspot.com"
    //     }
    // 		var secondInstance = firebase.initializeApp(config, tokenCreated);
    //
    // 		secondInstance.auth().createUserWithEmailAndPassword($scope.colleague.email, createdPsswd)
    // 			.then(function (userObj) {
    // 				if(userObj) {
    // 					var uid = userObj.uid;
    // 					var businesses = {};
    // 					$scope.businessesNames.forEach(function (item) {
    // 						if (item.name === business) {
    // 							currentBusiness = item.key;
    // 							return businesses[item.key] = true;
    // 						}
    // 					});
    //
    // 					createdData['pending-invitations/' + email] = {inviter: currentUid, token: {used: false}};
    // 					createdData['pending-invitations/' + email]['token'][tokenCreated] = true;
    // 					console.log('bs', businesses);
    // 					createdData['users/' + uid] = {
    // 							businesses: businesses,
    // 							currentBusiness: currentBusiness,
    // 							email: email.replace(/,/g, '.'),
    // 							role: role,
    // 							created_at: moment().format(),
    // 							displayName: name,
    // 							photoURL: "http://www.pdhbook.com/Images/User/default_image/User_Placeholder.png"
    // 						};
    //
    // 					if (document.getElementById('toast-container') !== null) {
    // 						toastr.clear();
    // 					}
    //
    // 					FirebaseRef.update(createdData).then(function () {
    // 							$http({
    // 								method: 'POST',
    // 								url: 'http://ondecode-mailer.herokuapp.com/ez-table/send-invitation',
    // 								data: {token: tokenCreated, email: userObj.email, pswd: createdPsswd}
    // 							}).then(function (response) {
    // 								if(response.status === 200) {
    // 									$scope.close();
    // 									return toastr.success('Invitation sent!');
    // 								} else {
    // 									$scope.close();
    // 									return toastr.error("Couldn't send invitation");
    // 								}
    // 							}).catch(function (error) {
    // 								$scope.close();
    // 								return toastr.error("Couldn't send invitation");
    // 							});
    // 					});
    // 				}
    // 				secondInstance.auth().signOut();
    // 			});
    //
    // 		$scope.close();
    // },
    createUser: function (params) {
      var config = {
              apiKey: "AIzaSyBT0J4nnPxcppjP68NozgyGajiglYQJvbw",
              authDomain: "ez-table-80d9c.firebaseapp.com",
              databaseURL: "https://ez-table-80d9c.firebaseio.com",
              storageBucket: "ez-table-80d9c.appspot.com"
          };
      var secondInstance = firebase.initializeApp(config, params.tokenCreated);
  		return secondInstance.auth().createUserWithEmailAndPassword(params.email, params.createdPsswd)
  			.then(function (userObj) {

          var createdData = {};
					var uid = userObj.uid;
					var businesses = {};
          var currentBusiness;
					params.businessesNames.forEach(function (item) {
						if (item.name === params.business) {
							currentBusiness = item.key;
							return businesses[item.key] = true;
						}
					});

					createdData['users/' + uid] = {
							businesses: businesses,
							currentBusiness: params.currentBusiness,
							email: params.email.replace(/,/g, '.'),
							role: params.role,
							created_at: moment().format(),
							displayName: params.name,
							photoURL: "http://www.pdhbook.com/Images/User/default_image/User_Placeholder.png"
						};

					FirebaseRef.update(createdData);
        }).catch(function (error) { return error });
    },
    sendTable: function (params) {

    },

    send: function (params) {
      var options = {
				method: 'POST',
				url: 'http://ondecode-mailer.herokuapp.com/ez-table/send-invitation',
				data: {token: params.tokenCreated, email: params.email, pswd: params.createdPsswd}
      }
			params.flags ? (options.params = params.flags) : '';
      $http(options).then(function (response) {
				if(response.status === 200) {
					return toastr.success('Invitation sent!');
				} else {
					return toastr.error("Couldn't send invitation " + response.statusText);
				}
			}).catch(function (error) {
				return toastr.error("Couldn't send invitation " + error.message);
			});
    }
  }
}]);
