
var CreateSmartTableCtrl = function($scope, FirebaseRef, Users, $firebaseArray, Auth, $state, $uibModal){
	$scope.selection = {
		selectedColumns: ''
	}

	$scope.complete = false;

	$scope.alert = {
		title: 'Before creating this new table, first you need to select at least one of the default columns so you can use your table correctly.',
		content: 'Remember that this is only for you to keep track of which element you are adding information to, so we recommend you to use a name or maybe an ID column.',
		show: false
	}

	var getRef = function(){
		var ref = {
			firebase: null
		};
		switch($scope.newSmartTable.for){
			case 'business':
				ref.firebase = FirebaseRef.child('businesses/' + $scope.currentUser.currentBusiness);
				break;
			case 'group': break;
			case 'user':
				ref.firebase = FirebaseRef.child('users/' + $scope.currentUser.$id).child('businesses/' + $scope.currentUser.currentBusiness);
				break;
			default: break;
		}
		return ref;
	}

	$scope.newSmartTable = {
		name: null,
		for: null,
		columns: []
	}

	//This will be iterated in order to be pushed as the columns of the new smart table object
	$scope.columns = {
		"default": {
			title: $scope.$parent.tab.title,
			columns: []
		}
	}

	$scope.availableColumns = $scope.$parent.tab.columns;

	$scope.$watch('selection.selectedColumns', function (value) {
		if (_.isEmpty(value)) return;
		var pos = _.indexOf($scope.columns['default'].columns, value);
		$scope.columns['default'].columns = value;
	}, true);

	var canProceed = function(){
		var flag = true,
			keys = Object.keys($scope.columns);
		if(keys.length === 1 && _.indexOf(keys, 'custom') !== -1){
			return false;
		}
		for (var key in $scope.columns) {
			if(key === 'custom'){
				continue;
			}
			var count = _.filter($scope.columns[key].columns, function(c) { return c.checked; });
			flag = count.length > 0;
		}
		return flag;
	}

	var pushTable = function(){
		var ref = getRef(),
			order = 0;
		if(ref.firebase === null){
			toastr.error('Something went wrong');
			return false;
		}

		for (var key in $scope.columns) {
			for(var i = 0; i < $scope.columns[key].columns.length; i++){
				if($scope.columns[key].columns[i].checked){
					var column = $scope.columns[key].columns[i];
					if(key === 'custom' && !column.db){
						//Add to the database
						FirebaseRef.child('businesses/' + $scope.currentUser.currentBusiness)
								   .child('custom-columns/' + $scope.section)
								   .push(_.omit(column, ['checked', 'created']));
					}
					column.order = order++;
					$scope.newSmartTable.columns.push(_.omit(column, ['checked']));
				}
			}
		}
		var owner = $firebaseArray(ref.firebase.child('eztables/' + $scope.section));
		owner.$add({
			title: $scope.newSmartTable.name,
			columns: $scope.newSmartTable.columns
		}).then(function(table){
			$scope.selectTabTable($scope.section, table.key);
			toastr.success('Table was created!');
		});
	}

	$scope.createSmartTable = function(isValid){
		if(!isValid || $scope.columns.length === 0){
			return false;
		}
		pushTable();
	}

	$scope.createColumns = function(){
		var modalInstance = $uibModal.open({
			templateUrl: 'partials/modals/columnModal.html',
			scope: $scope,
			controller: 'ColumnCtrl',
			resolve: {
				columns: function(){
					return _.filter($scope.availableColumns, function(col){ return col.type === 'number' || col.type === 'currency'});
				},
				multiple: function () {
					return true;
				},
				column: function () {
					return null;
				},
				modifying: function () {
					return false;
				}
			}
		});
		modalInstance.result.then(function (result) {
			if(!result){
				return false;
			}
			if(!angular.isDefined($scope.columns['custom'])){
				$scope.columns['custom'] = {
					title: result.title,
					columns: []
				};
			}
			$scope.columns['custom'].columns = result.columns;
		}, function () {});
	}

	var countSelected = function(group){
		var count = _.filter(group.columns, function(c) { return c.checked; });
		group.selected = count.length;
		group.all = group.columns.length === count.length;
	}

	$scope.selectAll = function(group){
		var value = group.all;
		angular.forEach(group.columns, function(column){
			column.checked = value;
			$scope.toggleFromSelection(group, column);
		});
		countSelected(group);
	}

	$scope.toggleFromSelection = function(group, column){
		countSelected(group);
	}

	$scope.$watch('columns', function(columns){
		if(_.isEmpty(columns)){
			return false;
		}
		$scope.complete = canProceed();
		$scope.alert.show = !$scope.complete;
	}, true)

}

CreateSmartTableCtrl.$inject = ['$scope', 'FirebaseRef', 'Users', '$firebaseArray', 'Auth', '$state', '$uibModal'];

angular.module('ezTable.controllers')

.controller('CreateSmartTableCtrl', CreateSmartTableCtrl);
