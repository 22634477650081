angular.module('ezTable.services')

.factory('Templates', ['$templateCache', function ($templateCache) {
    var 
    checklist = $templateCache.get('input-checklist.html'),
    currency = $templateCache.get('input-currency.html'),
    date = $templateCache.get('input-date.html'),
    email = $templateCache.get('input-email.html'),
    entity = $templateCache.get('input-entity.html'),
    file = $templateCache.get('input-file.html'),
    formula = $templateCache.get('input-formula.html'),
    notes = $templateCache.get('input-notes.html'),
    number = $templateCache.get('input-number.html'),
    select = $templateCache.get('input-select.html'),
    telephone = $templateCache.get('input-telephone.html'),
    text = $templateCache.get('input-text.html');
    
    return{
        get: function(type){
            var tmpl;
            switch(type){
                case 'checklist':
                   tmpl = checklist; 
                break;
                case 'currency':
                    tmpl = currency; 
                break;
                case 'date':
                    tmpl = date; 
                break;
                case 'email':
                    tmpl = email; 
                break;
                case 'entity':
                    tmpl = entity; 
                break;
                case 'file':
                    tmpl = file; 
                break;
                case 'formula':
                    tmpl = formula; 
                break;
                case 'notes':
                    tmpl = notes; 
                break;
                case 'number':
                    tmpl = number; 
                break;
                case 'select':
                    tmpl = select; 
                break;
                case 'telephone':
                    tmpl = telephone; 
                break;
                case 'text':
                    tmpl = text; 
                break;
            }
            return tmpl;
        }
    };
    
}]);


