
angular.module('ezTable.controllers')

.controller('LeftSidebarCtrl', ['$scope', function($scope){
	$scope.sections = [
		{ to: 'dashboard', name: 'Dashboard', icon: 'tachometer' },
		{ to: 'acquisitions', name: 'Acquisitions', icon: 'building' },
		{ to: 'deals', name: 'Deals', icon: 'home' },
		{ to: 'properties', name: 'Properties', icon: 'building' },
		{ to: 'vendors', name: 'Vendors', icon: 'users' },
		{ to: 'tickets', name: 'Tickets', icon: 'file' },
		{ to: 'clients', name: 'Clients', icon: 'users' }
	];
}]);