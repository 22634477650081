
angular.module('ezTable.controllers')

.controller('SmartTableCtrl', ['$scope', '$timeout', function($scope, $timeout){

	//Table-related attributes/methiods
	$scope.options = [10, 20, 30, 'All'];

	$scope.newRow = {};

	$scope.addToTable = function(collection, element){
		//AJAX call to the URL of this tab and on the promise execute the change in the UI
		console.log("POST to: " + $scope.tab.url);
		collection.push(element);
		$scope.newRow = {};
	}

}]);