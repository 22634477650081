angular.module('ezTable.directives')

.directive('checklist', ['Users', '$uibModal', 'AclService', function(Users, $uibModal, AclService){
	return{
		restrict: 'E',
		replace: true,
        scope: {
            parent: '=',
            target: '=',
            update: '=',
            item: '='
		},
		templateUrl: '/partials/directives/checklist.html',
        link: function (scope, element, attrs) {

            scope.can = AclService.can;

            var countSelected = function(checklist){
                var count = _.filter(checklist, function(c) { return c.checked; });
                scope.item.selected = count.length;
                scope.item.all = checklist.length === count.length;
                scope.item.percentage = Math.ceil((count.length / checklist.length) * 100);
                scope.$parent.updateItem(scope.parent);
            }

            scope.newTask = {};

            scope.tasks = [];

            scope.addTask = function (isValid) {
                if(!isValid){
                    return false;
                }
                if(!angular.isDefined(_.get(scope.parent, scope.target))){
                    var aux = {
                        checklist: [],
                        selected: 0,
                        all: false,
                        percentage: 0
                    };
                    _.set(scope.parent, scope.target, aux);
                }

                scope.item = _.get(scope.parent, scope.target);
                if (!angular.isDefined(scope.item.checklist)) {
                    scope.item.checklist = [];
                }
                scope.item.checklist.push(scope.newTask);
                countSelected(scope.item.checklist);
                scope.newTask = {};
            }

            scope.createChecklist = function(){
                scope.$parent.addToTable(scope.parent);
            }

            scope.toggleSelection = function(){
                countSelected(scope.item.checklist);
            }

            scope.selectAll = function(){
                var value = scope.item.all,
                    checklist = scope.item.checklist;
                angular.forEach(checklist, function(item){
                    item.checked = value;
                });
                countSelected(checklist);
            }

            scope.removeTask = function (task, event) {
                var modalInstance = $uibModal.open({
                    templateUrl: 'partials/modals/confirmationModal.html',
                    controller: 'ConfirmationCtrl',
                    // appendTo: $(event.target).closest('.checklist'),
                    resolve: {
                        msg: function () {
                            return "Are you sure you want to remove the task: "+ task.name +"?";
                        }
                    }
                });
                modalInstance.result.then(function (result) {
                    if (result) {
                        var item = _.get(scope.parent, scope.target),
                            pos = item.checklist.indexOf(task);
                        item.checklist.splice(pos, 1);
                        countSelected(item.checklist);
                        scope.$parent.updateItem(scope.parent);
                    }
                }, function () {});
            }
        }
	}
}]);