//entities that could be added as columns for the lookup tables grouped by section
/*var DEFAULT_TABLES = {
	eztables: {
		acquisitions:
		[
			{
				title:"Property",
				columns: [
					{key: 'prop_address', title: 'Property Address', type: 'multi'},
					{key: 'prop_name', title: 'Property Name', type: 'text'},
					{key: 'prop_indicator', title: 'Indicator', type: 'text'},
					{key: 'prop_status', title: 'Status', type: 'text'},
					{key: 'prop_storenumber', title: 'Store #', type: 'text'},
					{key: 'prop_sqfootage', title: 'SQ Footage', type: 'number'},
					{key: 'prop_noticedue', title: 'Notice Due', type: 'text'},
					{key: 'prop_dateacquired', title: 'Date Acquired', type: 'date'},
					{key: 'prop_datesold', title: 'Date Sold', type: 'date'},
					{key: 'prop_owner', title: 'Owner', type: 'multi'},
					{key: 'prop_monthlymgmtfeecurrency', title: 'Monthly MGMT Fee $', type: 'currency'},
					{key: 'prop_monthlymgmtfeepercentage', title: 'Monthly MGMT Fee %', type: 'number'},
					{key: 'prop_companyfee', title: 'Company Fee', type: 'number'},
					{key: 'prop_purchaseprice', title: 'Purchase Price', type: 'currency'},
					{key: 'prop_rent', title: 'Rent', type: 'currency'},
					{key: 'prop_caprate', title: 'Cap Rate', type: 'number'},
					{key: 'prop_termexpiration', title: 'Term Expiration', type: 'date'},
					{key: 'prop_numberofoptions', title: '# of Options', type: 'number'},
					{key: 'prop_optionperiodyears', title: 'Option Period Years', type: 'xxxxxx'},
					{key: 'prop_increases', title: 'Increases', type: 'xxxxxx'},
					{key: 'prop_typecontract', title: 'Type Contract', type: 'select', options:['NN','NNN']},
					{key: 'prop_duediligence', title: 'Due Diligence', type: 'multi'},//*******type for entity??
					{key: 'prop_optionterm', title: 'Option Term', type: 'xxxxxx'},
					{key: 'prop_noticedateforoptionterms', title: 'Notice Date for Option Term', type: 'date'},
					{key: 'prop_optionrents', title: 'Option Rents', type: 'xxxxxx'},
					{key: 'prop_maintenancecreditreceived', title: 'Maintenance Credit Received', type: 'number'},
					{key: 'prop_reportedsales', title: 'Reported Sales', type: 'currency'},
					{key: 'prop_msa', title: 'MSA', type: 'xxxxxx'},
					{key: 'prop_landlordresponsibility', title: 'Landlord Responsibility', type: 'xxxxxx'},
					{key: 'prop_parkingrequirements', title: 'Parking Requirements', type: 'select', options:['Yes','No']},
					{key: 'prop_cam', title: 'CAM', type: 'multi'},//*******type for entity??
					{key: 'prop_lender', title: 'Lender', type: 'multi'},//*******type for entity??
					{key: 'prop_rate', title: 'Rate', type: 'number'},
					{key: 'prop_type', title: 'Type', type: 'text'},
					{key: 'prop_amoortization', title: 'Amortization', type: 'currency'},
					{key: 'prop_duedate', title: 'Due Date', type: 'date'},
					{key: 'prop_roofsystem', title: 'Roof System', type: 'comments'},
					{key: 'prop_storemngremail', title: 'Store Manager Email', type: 'multi'},
					{key: 'prop_clientcontactdetails', title: 'Client Contact Details', type: 'comments'},
					{key: 'prop_rentholidaymonths', title: 'Rent Holiday Months', type: 'number'},
					{key: 'prop_rentholidayamount', title: 'Rent Holiday Amount', type: 'currency'},
					{key: 'prop_roofsystem', title: 'Roof System', type: 'comments'},
					{key: 'prop_storemngremail', title: 'Store Manager Email', type: 'multi'},
					{key: 'prop_clientcontactdetails', title: 'Client Contact Details', type: 'comments'},
					{key: 'prop_rentholidaymonths', title: 'Rent Holiday Months', type: 'number'},
					{key: 'prop_rentholidayamount', title: 'Rent Holiday Amount', type: 'number'}
				]
			},
			{
				title:"Due Diligence",
				columns:[
					{key: 'dd_property', title: 'Property', type: 'multi'}, //*******type for entity??
					{key: 'dd_leaseextension', title: 'Lease Extension', type: 'number'},
					{key: 'dd_effectivedateofpsa', title: 'Effective Date of PSA', type: 'date'},
					{key: 'dd_amendmentstopsa', title: 'Amendments to PSA?', type: 'select', options:['Yes','No']},
					{key: 'dd_buyingentity', title: 'Buying Entity', type: 'text'},
					{key: 'dd_earnestmoney', title: 'Earnest Money', type: 'number'},
					{key: 'dd_additionaldeposit', title: 'Additional Deposit?', type: 'select', options:['Yes','No']},
					{key: 'dd_inspectiondatestart', title: 'Inspection Date Start', type: 'date'},
					{key: 'dd_inspetiondateend', title: 'Inspection Date End', type: 'date'},
					{key: 'dd_dateofrofrrequestreceived', title: 'Date of ROFR Request / Received', type: 'date'},
					{key: 'dd_snda', title: 'SNDA', type: 'xxxxxx'},
					{key: 'dd_dateofestoppelsrequestreceived', title: 'Date od Estoppels Request / Received', type: 'date'},
					{key: 'dd_expirationdate', title: 'Expiration Date', type: 'date'},
					{key: 'dd_closingdays', title: 'Closing (Days)', type: 'number'},
					{key: 'dd_closingdate', title: 'Closing Date', type: 'date'},
					{key: 'dd_deckcreated', title: 'Deck Created', type: 'text'},
					{key: 'dd_lender', title: 'Lender', type: 'multi'},//*********type for entity??
					{key: 'dd_titlecompany', title: 'Title Company', type: 'text'},
					{key: 'dd_sitevisitdate', title: 'Site Visit (Date)', type: 'date'},
					{key: 'dd_sitevisitwriteup', title: 'Site Visit Writeup', type: 'comments'},///????
					{key: 'dd_checkdotplans', title: 'Check Dot Plans', type: 'text'},
					{key: 'dd_reportedsales', title: 'Reported Sales', type: 'number'},
					{key: 'dd_roofwarrantyxtension', title: 'Roof Warranty-Extension?', type: 'select', options:['Yes','No']},
					{key: 'dd_reviewofleasebyl2', title: 'Review of Lease by L2', type: 'text'},
					{key: 'dd_reviewofamendmentsbyl2', title: 'Review of Amendments by L2', type: 'text'},
					{key: 'dd_reviewofeasementagreementbyl2', title: 'Review of Easement Agreement by L2', type: 'text'},
					{key: 'dd_phasei', title: 'Phase I (ESA)', type: 'date'},
					{key: 'dd_reviewofphaseibyl2', title: 'Review of Phase I by L2', type: 'text'},
					{key: 'dd_propertyinvestigationreport', title: 'Property Investigation Report (PCA)', type: 'date'},
					{key: 'dd_reviewofpcabyl2', title: 'Review of PCA by L2', type: 'text'},
					{key: 'dd_appraisals', title: 'Appraisals', type: 'date'},
					{key: 'dd_reviewofappraisalsbyl2', title: 'Review of Appraisals by L2', type: 'text'},
					{key: 'dd_reviewoftitlecommitmentbyl2', title: 'Review of Title Commitment by L2', type: 'text'},
					{key: 'dd_altasurvey', title: 'Alta Survey', type: 'date'},
					{key: 'dd_reviewofsurveybyl2', title: 'Review of Survey by L2', type: 'text'},
					{key: 'dd_maintenancecreditnotes', title: 'Maintenance Credit / Notes', type: 'comments'},
					{key: 'dd_deliverydate', title: 'Delivery Date', type: 'date'},
					{key: 'dd_senttoseller', title: 'Sent to Seller', type: 'select', options:['Yes','No']},
					{key: 'dd_listingbroker', title: 'Listing Broker', type: 'text'},
					{key: 'dd_changeofownership', title: 'Change of Ownership', type: 'xxxxxx'},
					{key: 'dd_changeofaddressforretaxauthority', title: 'Change of Address for Re Tax Autority', type: 'xxxxxx'},
					{key: 'dd_costsegregation', title: 'Cost Segregation', type: 'number'},
					{key: 'dd_dateordered', title: 'Date Ordered', type: 'date'},
					{key: 'dd_datedue', title: 'Date Due', type: 'date'},
					{key: 'dd_locatedinfloodzone', title: 'Located in Flood Zone', type: 'select', options:['Yes','No']}
				]
			},
			{
				title:"Insurance",
				columns:[
					{key: 'ins_property', title: 'Insurance Property', type: 'multi'},
					{key: 'ins_name', title: 'Insurance Name', type: 'text'},
					{key: 'ins_type', title: 'Insurance Type', type: 'xxxxxx'},
					{key: 'ins_tenantpayforinsurance', title: 'Tenant Pay for Insurance', type: 'xxxxxx'},
					{key: 'ins_tenantissuesforinsurance', title: 'Tenant Issues for Insurance', type: 'xxxxxx'},
					{key: 'ins_reimbursable', title: 'Reimbursable', type: 'xxxxxx'},
					{key: 'ins_policy', title: 'Insurance Policy', type: 'xxxxxx'},
					{key: 'ins_amount', title: 'Insurance Amount', type: 'number'},
					{key: 'ins_policyissuedate', title: 'Policy Issue Date', type: 'date'},
					{key: 'ins_policyrenewaldate', title: 'Policy Renewal Date', type: 'date'},
					{key: 'ins_invoicedate', title: 'Invoice Date', type: 'date'},
					{key: 'ins_paymentreceived', title: 'Insurance Payment Received', type: 'xxxxxx'},
					{key: 'ins_paid', title: 'Paid', type: 'xxxxxx'},
					{key: 'ins_comments', title: 'Insurance Comments', type: 'comments'},
					{key: 'ins_location', title: 'Location', type: 'text'},
					{key: 'ins_effectivedate', title: 'Insurance Effective Date', type: 'date'},
					{key: 'ins_llprogram-coi', title: 'LL Program - COI', type: 'xxxxxx'},
					{key: 'ins_firealarm', title: 'Fire Alarm', type: 'xxxxxx'},
					{key: 'ins_tenantresponsibility', title: 'Tenant Responsibility', type: 'xxxxxx'},
					{key: 'ins_alarmcompany', title: 'Alarm Company', type: 'multi'},
					{key: 'ins_inspectiondate', title: 'Inspection Date', type: 'date'},
					{key: 'ins_paiddate', title: 'Paid Date', type: 'date'},
					{key: 'ins_expirationdate', title: 'Insurance Expiration Date', type: 'date'}
				]
			},
			{
				title:"Warranty",
				columns:[
					{key: 'warr_property', title: 'Warranty Property', type: 'multi'},
					{key: 'warr_warrantycompany', title: 'Warranty Company', type: 'multi'},
					{key: 'warr_warrantynumber', title: 'Warranty Number', type: 'number'},
					{key: 'warr_expirationdate', title: 'Warranty Expiration Date', type: 'date'},
					{key: 'warr_installdate', title: 'Install Date', type: 'date'},
					{key: 'warr_company', title: 'Company', type: 'multi'}
				]
			},
			{
				title:"Ticket",
				columns:[
					{key: 'ticket_property', title: 'Ticket Property', type: 'multi'},
					{key: 'ticket_number', title: 'Ticket Number', type: 'number'},
					{key: 'ticket_amount', title: 'Ticket Amount', type: 'number'},
					{key: 'ticket_approvedby', title: 'Ticket Approved by', type: 'multi'},//contains users
					{key: 'ticket_approvaldate', title: 'Approval Date', type: 'date'},
					{key: 'ticket_comments', title: 'Ticket Comments', type: 'comments'},
					{key: 'ticket_documents', title: 'Documents', type: 'comments'},
					{key: 'ticket_issue', title: 'Issue', type: 'comments'},
					{key: 'ticket_description', title: 'Description', type: 'comments'},
					{key: 'ticket_vendor', title: 'Ticket Vendor', type: 'multi'},
					{key: 'ticket_createdby', title: 'Ticket Created by', type: 'multi'},
					{key: 'ticket_expectedstartdate', title: 'Expected Start Date', type: 'date'},
					{key: 'ticket_expectedcompletiondate', title: 'Expected Completion Date', type: 'date'},
					{key: 'ticket_actualstartdate', title: 'Actual Start Date', type: 'date'},
					{key: 'ticket_actualcompletiondate', title: 'Actual Completion Date', type: 'date'},
					{key: 'ticket_tenant', title: 'Ticket Tenant', type: 'multi'},
					{key: 'ticket_warranty', title: 'Ticket Warranty', type: 'multi'},
					{key: 'ticket_client', title: 'Ticket Client', type: 'multi'},
					{key: 'ticket_paymentmethod', title: 'Ticket Payment Method', type: 'select', options:['Bill Pay','Client direct','Walgreens/CVS Direct']},
					{key: 'ticket_statusnotes', title: 'Ticket Status Notes', type: 'comments'},
					{key: 'ticket_typeofrepairorrequest', title: 'Type of Repair or Request', type: 'select', options:['Roof','EFIS','Parking','Exterior','Other']},
					{key: 'ticket_whomreportedissue', title: 'Whom Reported Issue', type: 'text'}
				]
			},
			{
				title:"Taxes",
				columns:[
					{key: 'taxes_property', title: 'Taxes Property', type: 'multi'},
					{key: 'taxes_amount', title: 'Taxes Amount', type: 'number'},
					{key: 'taxes_duedate', title: 'Taxes Due Date', type: 'date'},
					{key: 'taxes_reimbursable', title: 'Taxes Reimbursable', type: 'xxxxxx'},
					{key: 'taxes_invoicedate', title: 'Taxes Invoice Date', type: 'date'},
					{key: 'taxes_paymentreceived', title: 'Taxes Payment Received', type: 'number'},
					{key: 'taxes_paid', title: 'Taxes Paid', type: 'xxxxxx'},
					{key: 'taxes_comments', title: 'Taxes Comments', type: 'xxxxxx'}
				]
			},
			{
				title:"Maintenance",
				columns:[
					{key: 'mnnt_property', title: 'Maintenance Property', type: 'multi'},
					{key: 'mnnt_amount', title: 'Maintenance Amount', type: 'number'},
					{key: 'mnnt_item', title: 'Maintenance Item', type: 'xxxxxx'},
					{key: 'mnnt_reimbursable', title: 'Maintenance Reimbursable', type: 'xxxxxx'},
					{key: 'mnnt_invoicedate', title: 'Maintenance Invoice Date', type: 'date'},
					{key: 'mnnt_paymentreceived', title: 'Maintenance Payment Received', type: 'xxxxxx'},
					{key: 'mnnt_paid', title: 'Maintenance Paid', type: 'xxxxxx'},
					{key: 'mnnt_comments', title: 'Maintenance Comments', type: 'comments'},
					{key: 'mnnt_duedate', title: 'Maintenance Due Date', type: 'date'}
				]

			},
			{
				title:"LLC",
				columns:[
					{key: 'llc_property', title: 'LLC Property', type: 'multi'},
					{key: 'llc_owner', title: 'LLC Owner', type: 'multi'},
					{key: 'llc_duedate', title: 'LLC Due Date', type: 'date'},
					{key: 'llc_reimbursable', title: 'LLC Reimbursable', type: 'xxxxxx'},
					{key: 'llc_amount', title: 'LLC Amount', type: 'number'},
					{key: 'llc_comments', title: 'LLC Comments', type: 'comments'},
					{key: 'llc_invoicedate', title: 'LLC Invoice Date', type: 'date'},
					{key: 'llc_paid', title: 'LLC Paid', type: 'xxxxxx'},
					{key: 'llc_accountnumber', title: 'LLC Account Number', type: 'number'}
				]

			},
			{
				title:"Foreign LLC",
				columns:[
					{key: 'fllc_property', title: 'Foreign LLC Property', type: 'multi'},
					{key: 'fllc_owner', title: 'Foreign LLC Owner', type: 'multi'},
					{key: 'fllc_duedate', title: 'Foreign LLC Due Date', type: 'date'},
					{key: 'fllc_reimbursable', title: 'Foreign LLC Reimbursable', type: 'xxxxxx'},
					{key: 'fllc_amount', title: 'Foreign LLC Amount', type: 'number'},
					{key: 'fllc_comments', title: 'Foreign LLC Comments', type: 'comments'},
					{key: 'fllc_invoicedate', title: 'Foreign LLC Invoice Date', type: 'date'},
					{key: 'fllc_paid', title: 'Foreign LLC Paid', type: 'xxxxxx'},
					{key: 'fllc_accountnumber', title: 'Foreign LLC Account Number', type: 'number'}
				]

			},
			{
				title:"GMEI",
				columns:[
					{key: 'gmei_property', title: 'GMEI Property', type: 'multi'},
					{key: 'gmei_owner', title: 'GMEI Owner', type: 'multi'},
					{key: 'gmei_duedate', title: 'GMEI Due Date', type: 'date'},
					{key: 'gmei_reimbursable', title: 'GMEI Reimbursable', type: 'xxxxxx'},
					{key: 'gmei_amount', title: 'GMEI Amount', type: 'number'},
					{key: 'gmei_comments', title: 'GMEI Comments', type: 'comments'},
					{key: 'gmei_invoicedate', title: 'GMEI Invoice Date', type: 'date'},
					{key: 'gmei_paid', title: 'GMEI Paid', type: 'xxxxxx'},
					{key: 'gmei_accountnumber', title: 'GMEI Account Number', type: 'number'}
				]

			},
			{
				title:"Foreign Agent Renewall",
				columns:[
					{key: 'fagent_property', title: 'Foreign Agent Property', type: 'multi'},
					{key: 'fagent_duedate', title: 'Foreign Agent Due Date', type: 'date'},
					{key: 'fagent_reimbursable', title: 'Foreign Agent Reimbursable', type: 'xxxxxx'},
					{key: 'fagent_amount', title: 'Foreign Agent Amount', type: 'number'},
					{key: 'fagent_comments', title: 'Foreign Agent Comments', type: 'comments'},
					{key: 'fagent_invoicedate', title: 'Foreign Agent Invoice Date', type: 'date'},
					{key: 'fagent_paid', title: 'Foreign Agent Paid', type: 'xxxxxx'},
					{key: 'fagent_accountnumber', title: 'Foreign Agent Account Number', type: 'number'},
					{key: 'fagent_agent', title: 'Agent', type: 'multi'}
				]

			}
		],
		deals:
		[
			{
				title:"Property",
				columns: [
					{key: 'prop_address', title: 'Property Address', type: 'multi'},
					{key: 'prop_name', title: 'Property Name', type: 'text'},
					{key: 'prop_indicator', title: 'Indicator', type: 'text'},
					{key: 'prop_status', title: 'Status', type: 'text'},
					{key: 'prop_storenumber', title: 'Store #', type: 'text'},
					{key: 'prop_sqfootage', title: 'SQ Footage', type: 'number'},
					{key: 'prop_noticedue', title: 'Notice Due', type: 'text'},
					{key: 'prop_dateacquired', title: 'Date Acquired', type: 'date'},
					{key: 'prop_datesold', title: 'Date Sold', type: 'date'},
					{key: 'prop_owner', title: 'Owner', type: 'multi'},
					{key: 'prop_monthlymgmtfeecurrency', title: 'Monthly MGMT Fee $', type: 'currency'},
					{key: 'prop_monthlymgmtfeepercentage', title: 'Monthly MGMT Fee %', type: 'number'},
					{key: 'prop_companyfee', title: 'Company Fee', type: 'number'},
					{key: 'prop_purchaseprice', title: 'Purchase Price', type: 'currency'},
					{key: 'prop_rent', title: 'Rent', type: 'currency'},
					{key: 'prop_caprate', title: 'Cap Rate', type: 'number'},
					{key: 'prop_termexpiration', title: 'Term Expiration', type: 'date'},
					{key: 'prop_numberofoptions', title: '# of Options', type: 'number'},
					{key: 'prop_optionperiodyears', title: 'Option Period Years', type: 'xxxxxx'},
					{key: 'prop_increases', title: 'Increases', type: 'xxxxxx'},
					{key: 'prop_typecontract', title: 'Type Contract', type: 'select', options:['NN','NNN']},
					{key: 'prop_duediligence', title: 'Due Diligence', type: 'multi'},//*******type for entity??
					{key: 'prop_optionterm', title: 'Option Term', type: 'xxxxxx'},
					{key: 'prop_noticedateforoptionterms', title: 'Notice Date for Option Term', type: 'date'},
					{key: 'prop_optionrents', title: 'Option Rents', type: 'xxxxxx'},
					{key: 'prop_maintenancecreditreceived', title: 'Maintenance Credit Received', type: 'number'},
					{key: 'prop_reportedsales', title: 'Reported Sales', type: 'currency'},
					{key: 'prop_msa', title: 'MSA', type: 'xxxxxx'},
					{key: 'prop_landlordresponsibility', title: 'Landlord Responsibility', type: 'xxxxxx'},
					{key: 'prop_parkingrequirements', title: 'Parking Requirements', type: 'select', options:['Yes','No']},
					{key: 'prop_cam', title: 'CAM', type: 'multi'},//*******type for entity??
					{key: 'prop_lender', title: 'Lender', type: 'multi'},//*******type for entity??
					{key: 'prop_rate', title: 'Rate', type: 'number'},
					{key: 'prop_type', title: 'Type', type: 'text'},
					{key: 'prop_amoortization', title: 'Amortization', type: 'currency'},
					{key: 'prop_duedate', title: 'Due Date', type: 'date'},
					{key: 'prop_roofsystem', title: 'Roof System', type: 'comments'},
					{key: 'prop_storemngremail', title: 'Store Manager Email', type: 'multi'},
					{key: 'prop_clientcontactdetails', title: 'Client Contact Details', type: 'comments'},
					{key: 'prop_rentholidaymonths', title: 'Rent Holiday Months', type: 'number'},
					{key: 'prop_rentholidayamount', title: 'Rent Holiday Amount', type: 'currency'},
					{key: 'prop_roofsystem', title: 'Roof System', type: 'comments'},
					{key: 'prop_storemngremail', title: 'Store Manager Email', type: 'multi'},
					{key: 'prop_clientcontactdetails', title: 'Client Contact Details', type: 'comments'},
					{key: 'prop_rentholidaymonths', title: 'Rent Holiday Months', type: 'number'},
					{key: 'prop_rentholidayamount', title: 'Rent Holiday Amount', type: 'number'}
				]
			},
			{
				title:"Due Diligence",
				columns:[
					{key: 'dd_property', title: 'Property', type: 'multi'}, //*******type for entity??
					{key: 'dd_leaseextension', title: 'Lease Extension', type: 'number'},
					{key: 'dd_effectivedateofpsa', title: 'Effective Date of PSA', type: 'date'},
					{key: 'dd_amendmentstopsa', title: 'Amendments to PSA?', type: 'select', options:['Yes','No']},
					{key: 'dd_buyingentity', title: 'Buying Entity', type: 'text'},
					{key: 'dd_earnestmoney', title: 'Earnest Money', type: 'number'},
					{key: 'dd_additionaldeposit', title: 'Additional Deposit?', type: 'select', options:['Yes','No']},
					{key: 'dd_inspectiondatestart', title: 'Inspection Date Start', type: 'date'},
					{key: 'dd_inspetiondateend', title: 'Inspection Date End', type: 'date'},
					{key: 'dd_dateofrofrrequestreceived', title: 'Date of ROFR Request / Received', type: 'date'},
					{key: 'dd_snda', title: 'SNDA', type: 'xxxxxx'},
					{key: 'dd_dateofestoppelsrequestreceived', title: 'Date od Estoppels Request / Received', type: 'date'},
					{key: 'dd_expirationdate', title: 'Expiration Date', type: 'date'},
					{key: 'dd_closingdays', title: 'Closing (Days)', type: 'number'},
					{key: 'dd_closingdate', title: 'Closing Date', type: 'date'},
					{key: 'dd_deckcreated', title: 'Deck Created', type: 'text'},
					{key: 'dd_lender', title: 'Lender', type: 'multi'},//*********type for entity??
					{key: 'dd_titlecompany', title: 'Title Company', type: 'text'},
					{key: 'dd_sitevisitdate', title: 'Site Visit (Date)', type: 'date'},
					{key: 'dd_sitevisitwriteup', title: 'Site Visit Writeup', type: 'comments'},///????
					{key: 'dd_checkdotplans', title: 'Check Dot Plans', type: 'text'},
					{key: 'dd_reportedsales', title: 'Reported Sales', type: 'number'},
					{key: 'dd_roofwarrantyxtension', title: 'Roof Warranty-Extension?', type: 'select', options:['Yes','No']},
					{key: 'dd_reviewofleasebyl2', title: 'Review of Lease by L2', type: 'text'},
					{key: 'dd_reviewofamendmentsbyl2', title: 'Review of Amendments by L2', type: 'text'},
					{key: 'dd_reviewofeasementagreementbyl2', title: 'Review of Easement Agreement by L2', type: 'text'},
					{key: 'dd_phasei', title: 'Phase I (ESA)', type: 'date'},
					{key: 'dd_reviewofphaseibyl2', title: 'Review of Phase I by L2', type: 'text'},
					{key: 'dd_propertyinvestigationreport', title: 'Property Investigation Report (PCA)', type: 'date'},
					{key: 'dd_reviewofpcabyl2', title: 'Review of PCA by L2', type: 'text'},
					{key: 'dd_appraisals', title: 'Appraisals', type: 'date'},
					{key: 'dd_reviewofappraisalsbyl2', title: 'Review of Appraisals by L2', type: 'text'},
					{key: 'dd_reviewoftitlecommitmentbyl2', title: 'Review of Title Commitment by L2', type: 'text'},
					{key: 'dd_altasurvey', title: 'Alta Survey', type: 'date'},
					{key: 'dd_reviewofsurveybyl2', title: 'Review of Survey by L2', type: 'text'},
					{key: 'dd_maintenancecreditnotes', title: 'Maintenance Credit / Notes', type: 'comments'},
					{key: 'dd_deliverydate', title: 'Delivery Date', type: 'date'},
					{key: 'dd_senttoseller', title: 'Sent to Seller', type: 'select', options:['Yes','No']},
					{key: 'dd_listingbroker', title: 'Listing Broker', type: 'text'},
					{key: 'dd_changeofownership', title: 'Change of Ownership', type: 'xxxxxx'},
					{key: 'dd_changeofaddressforretaxauthority', title: 'Change of Address for Re Tax Autority', type: 'xxxxxx'},
					{key: 'dd_costsegregation', title: 'Cost Segregation', type: 'number'},
					{key: 'dd_dateordered', title: 'Date Ordered', type: 'date'},
					{key: 'dd_datedue', title: 'Date Due', type: 'date'},
					{key: 'dd_locatedinfloodzone', title: 'Located in Flood Zone', type: 'select', options:['Yes','No']}
				]
			},
			{
				title:"Insurance",
				columns:[
					{key: 'ins_property', title: 'Insurance Property', type: 'multi'},
					{key: 'ins_name', title: 'Insurance Name', type: 'text'},
					{key: 'ins_type', title: 'Insurance Type', type: 'xxxxxx'},
					{key: 'ins_tenantpayforinsurance', title: 'Tenant Pay for Insurance', type: 'xxxxxx'},
					{key: 'ins_tenantissuesforinsurance', title: 'Tenant Issues for Insurance', type: 'xxxxxx'},
					{key: 'ins_reimbursable', title: 'Reimbursable', type: 'xxxxxx'},
					{key: 'ins_policy', title: 'Insurance Policy', type: 'xxxxxx'},
					{key: 'ins_amount', title: 'Insurance Amount', type: 'number'},
					{key: 'ins_policyissuedate', title: 'Policy Issue Date', type: 'date'},
					{key: 'ins_policyrenewaldate', title: 'Policy Renewal Date', type: 'date'},
					{key: 'ins_invoicedate', title: 'Invoice Date', type: 'date'},
					{key: 'ins_paymentreceived', title: 'Insurance Payment Received', type: 'xxxxxx'},
					{key: 'ins_paid', title: 'Paid', type: 'xxxxxx'},
					{key: 'ins_comments', title: 'Insurance Comments', type: 'comments'},
					{key: 'ins_location', title: 'Location', type: 'text'},
					{key: 'ins_effectivedate', title: 'Insurance Effective Date', type: 'date'},
					{key: 'ins_llprogram-coi', title: 'LL Program - COI', type: 'xxxxxx'},
					{key: 'ins_firealarm', title: 'Fire Alarm', type: 'xxxxxx'},
					{key: 'ins_tenantresponsibility', title: 'Tenant Responsibility', type: 'xxxxxx'},
					{key: 'ins_alarmcompany', title: 'Alarm Company', type: 'multi'},
					{key: 'ins_inspectiondate', title: 'Inspection Date', type: 'date'},
					{key: 'ins_paiddate', title: 'Paid Date', type: 'date'},
					{key: 'ins_expirationdate', title: 'Insurance Expiration Date', type: 'date'}
				]
			},
			{
				title:"Warranty",
				columns:[
					{key: 'warr_property', title: 'Warranty Property', type: 'multi'},
					{key: 'warr_warrantycompany', title: 'Warranty Company', type: 'multi'},
					{key: 'warr_warrantynumber', title: 'Warranty Number', type: 'number'},
					{key: 'warr_expirationdate', title: 'Warranty Expiration Date', type: 'date'},
					{key: 'warr_installdate', title: 'Install Date', type: 'date'},
					{key: 'warr_company', title: 'Company', type: 'multi'}
				]
			},
			{
				title:"Ticket",
				columns:[
					{key: 'ticket_property', title: 'Ticket Property', type: 'multi'},
					{key: 'ticket_number', title: 'Ticket Number', type: 'number'},
					{key: 'ticket_amount', title: 'Ticket Amount', type: 'number'},
					{key: 'ticket_approvedby', title: 'Ticket Approved by', type: 'multi'},//contains users
					{key: 'ticket_approvaldate', title: 'Approval Date', type: 'date'},
					{key: 'ticket_comments', title: 'Ticket Comments', type: 'comments'},
					{key: 'ticket_documents', title: 'Documents', type: 'comments'},
					{key: 'ticket_issue', title: 'Issue', type: 'comments'},
					{key: 'ticket_description', title: 'Description', type: 'comments'},
					{key: 'ticket_vendor', title: 'Ticket Vendor', type: 'multi'},
					{key: 'ticket_createdby', title: 'Ticket Created by', type: 'multi'},
					{key: 'ticket_expectedstartdate', title: 'Expected Start Date', type: 'date'},
					{key: 'ticket_expectedcompletiondate', title: 'Expected Completion Date', type: 'date'},
					{key: 'ticket_actualstartdate', title: 'Actual Start Date', type: 'date'},
					{key: 'ticket_actualcompletiondate', title: 'Actual Completion Date', type: 'date'},
					{key: 'ticket_tenant', title: 'Ticket Tenant', type: 'multi'},
					{key: 'ticket_warranty', title: 'Ticket Warranty', type: 'multi'},
					{key: 'ticket_client', title: 'Ticket Client', type: 'multi'},
					{key: 'ticket_paymentmethod', title: 'Ticket Payment Method', type: 'select', options:['Bill Pay','Client direct','Walgreens/CVS Direct']},
					{key: 'ticket_statusnotes', title: 'Ticket Status Notes', type: 'comments'},
					{key: 'ticket_typeofrepairorrequest', title: 'Type of Repair or Request', type: 'select', options:['Roof','EFIS','Parking','Exterior','Other']},
					{key: 'ticket_whomreportedissue', title: 'Whom Reported Issue', type: 'text'}
				]
			},
			{
				title:"Taxes",
				columns:[
					{key: 'taxes_property', title: 'Taxes Property', type: 'multi'},
					{key: 'taxes_amount', title: 'Taxes Amount', type: 'number'},
					{key: 'taxes_duedate', title: 'Taxes Due Date', type: 'date'},
					{key: 'taxes_reimbursable', title: 'Taxes Reimbursable', type: 'xxxxxx'},
					{key: 'taxes_invoicedate', title: 'Taxes Invoice Date', type: 'date'},
					{key: 'taxes_paymentreceived', title: 'Taxes Payment Received', type: 'number'},
					{key: 'taxes_paid', title: 'Taxes Paid', type: 'xxxxxx'},
					{key: 'taxes_comments', title: 'Taxes Comments', type: 'xxxxxx'}
				]
			},
			{
				title:"Maintenance",
				columns:[
					{key: 'mnnt_property', title: 'Maintenance Property', type: 'multi'},
					{key: 'mnnt_amount', title: 'Maintenance Amount', type: 'number'},
					{key: 'mnnt_item', title: 'Maintenance Item', type: 'xxxxxx'},
					{key: 'mnnt_reimbursable', title: 'Maintenance Reimbursable', type: 'xxxxxx'},
					{key: 'mnnt_invoicedate', title: 'Maintenance Invoice Date', type: 'date'},
					{key: 'mnnt_paymentreceived', title: 'Maintenance Payment Received', type: 'xxxxxx'},
					{key: 'mnnt_paid', title: 'Maintenance Paid', type: 'xxxxxx'},
					{key: 'mnnt_comments', title: 'Maintenance Comments', type: 'comments'},
					{key: 'mnnt_duedate', title: 'Maintenance Due Date', type: 'date'}
				]

			},
			{
				title:"LLC",
				columns:[
					{key: 'llc_property', title: 'LLC Property', type: 'multi'},
					{key: 'llc_owner', title: 'LLC Owner', type: 'multi'},
					{key: 'llc_duedate', title: 'LLC Due Date', type: 'date'},
					{key: 'llc_reimbursable', title: 'LLC Reimbursable', type: 'xxxxxx'},
					{key: 'llc_amount', title: 'LLC Amount', type: 'number'},
					{key: 'llc_comments', title: 'LLC Comments', type: 'comments'},
					{key: 'llc_invoicedate', title: 'LLC Invoice Date', type: 'date'},
					{key: 'llc_paid', title: 'LLC Paid', type: 'xxxxxx'},
					{key: 'llc_accountnumber', title: 'LLC Account Number', type: 'number'}
				]

			},
			{
				title:"Foreign LLC",
				columns:[
					{key: 'fllc_property', title: 'Foreign LLC Property', type: 'multi'},
					{key: 'fllc_owner', title: 'Foreign LLC Owner', type: 'multi'},
					{key: 'fllc_duedate', title: 'Foreign LLC Due Date', type: 'date'},
					{key: 'fllc_reimbursable', title: 'Foreign LLC Reimbursable', type: 'xxxxxx'},
					{key: 'fllc_amount', title: 'Foreign LLC Amount', type: 'number'},
					{key: 'fllc_comments', title: 'Foreign LLC Comments', type: 'comments'},
					{key: 'fllc_invoicedate', title: 'Foreign LLC Invoice Date', type: 'date'},
					{key: 'fllc_paid', title: 'Foreign LLC Paid', type: 'xxxxxx'},
					{key: 'fllc_accountnumber', title: 'Foreign LLC Account Number', type: 'number'}
				]

			},
			{
				title:"GMEI",
				columns:[
					{key: 'gmei_property', title: 'GMEI Property', type: 'multi'},
					{key: 'gmei_owner', title: 'GMEI Owner', type: 'multi'},
					{key: 'gmei_duedate', title: 'GMEI Due Date', type: 'date'},
					{key: 'gmei_reimbursable', title: 'GMEI Reimbursable', type: 'xxxxxx'},
					{key: 'gmei_amount', title: 'GMEI Amount', type: 'number'},
					{key: 'gmei_comments', title: 'GMEI Comments', type: 'comments'},
					{key: 'gmei_invoicedate', title: 'GMEI Invoice Date', type: 'date'},
					{key: 'gmei_paid', title: 'GMEI Paid', type: 'xxxxxx'},
					{key: 'gmei_accountnumber', title: 'GMEI Account Number', type: 'number'}
				]

			},
			{
				title:"Foreign Agent Renewall",
				columns:[
					{key: 'fagent_property', title: 'Foreign Agent Property', type: 'multi'},
					{key: 'fagent_duedate', title: 'Foreign Agent Due Date', type: 'date'},
					{key: 'fagent_reimbursable', title: 'Foreign Agent Reimbursable', type: 'xxxxxx'},
					{key: 'fagent_amount', title: 'Foreign Agent Amount', type: 'number'},
					{key: 'fagent_comments', title: 'Foreign Agent Comments', type: 'comments'},
					{key: 'fagent_invoicedate', title: 'Foreign Agent Invoice Date', type: 'date'},
					{key: 'fagent_paid', title: 'Foreign Agent Paid', type: 'xxxxxx'},
					{key: 'fagent_accountnumber', title: 'Foreign Agent Account Number', type: 'number'},
					{key: 'fagent_agent', title: 'Agent', type: 'multi'}
				]

			}
		],
		properties:
		[
			{
				title:"Property",
				columns: [
					{key: 'prop_address', title: 'Property Address', type: 'multi'},
					{key: 'prop_name', title: 'Property Name', type: 'text'},
					{key: 'prop_indicator', title: 'Indicator', type: 'text'},
					{key: 'prop_status', title: 'Status', type: 'text'},
					{key: 'prop_storenumber', title: 'Store #', type: 'text'},
					{key: 'prop_sqfootage', title: 'SQ Footage', type: 'number'},
					{key: 'prop_noticedue', title: 'Notice Due', type: 'text'},
					{key: 'prop_dateacquired', title: 'Date Acquired', type: 'date'},
					{key: 'prop_datesold', title: 'Date Sold', type: 'date'},
					{key: 'prop_owner', title: 'Owner', type: 'multi'},
					{key: 'prop_monthlymgmtfeecurrency', title: 'Monthly MGMT Fee $', type: 'currency'},
					{key: 'prop_monthlymgmtfeepercentage', title: 'Monthly MGMT Fee %', type: 'number'},
					{key: 'prop_companyfee', title: 'Company Fee', type: 'number'},
					{key: 'prop_purchaseprice', title: 'Purchase Price', type: 'currency'},
					{key: 'prop_rent', title: 'Rent', type: 'currency'},
					{key: 'prop_caprate', title: 'Cap Rate', type: 'number'},
					{key: 'prop_termexpiration', title: 'Term Expiration', type: 'date'},
					{key: 'prop_numberofoptions', title: '# of Options', type: 'number'},
					{key: 'prop_optionperiodyears', title: 'Option Period Years', type: 'xxxxxx'},
					{key: 'prop_increases', title: 'Increases', type: 'xxxxxx'},
					{key: 'prop_typecontract', title: 'Type Contract', type: 'select', options:['NN','NNN']},
					{key: 'prop_duediligence', title: 'Due Diligence', type: 'multi'},//*******type for entity??
					{key: 'prop_optionterm', title: 'Option Term', type: 'xxxxxx'},
					{key: 'prop_noticedateforoptionterms', title: 'Notice Date for Option Term', type: 'date'},
					{key: 'prop_optionrents', title: 'Option Rents', type: 'xxxxxx'},
					{key: 'prop_maintenancecreditreceived', title: 'Maintenance Credit Received', type: 'number'},
					{key: 'prop_reportedsales', title: 'Reported Sales', type: 'currency'},
					{key: 'prop_msa', title: 'MSA', type: 'xxxxxx'},
					{key: 'prop_landlordresponsibility', title: 'Landlord Responsibility', type: 'xxxxxx'},
					{key: 'prop_parkingrequirements', title: 'Parking Requirements', type: 'select', options:['Yes','No']},
					{key: 'prop_cam', title: 'CAM', type: 'multi'},//*******type for entity??
					{key: 'prop_lender', title: 'Lender', type: 'multi'},//*******type for entity??
					{key: 'prop_rate', title: 'Rate', type: 'number'},
					{key: 'prop_type', title: 'Type', type: 'text'},
					{key: 'prop_amoortization', title: 'Amortization', type: 'currency'},
					{key: 'prop_duedate', title: 'Due Date', type: 'date'},
					{key: 'prop_roofsystem', title: 'Roof System', type: 'comments'},
					{key: 'prop_storemngremail', title: 'Store Manager Email', type: 'multi'},
					{key: 'prop_clientcontactdetails', title: 'Client Contact Details', type: 'comments'},
					{key: 'prop_rentholidaymonths', title: 'Rent Holiday Months', type: 'number'},
					{key: 'prop_rentholidayamount', title: 'Rent Holiday Amount', type: 'currency'},
					{key: 'prop_roofsystem', title: 'Roof System', type: 'comments'},
					{key: 'prop_storemngremail', title: 'Store Manager Email', type: 'multi'},
					{key: 'prop_clientcontactdetails', title: 'Client Contact Details', type: 'comments'},
					{key: 'prop_rentholidaymonths', title: 'Rent Holiday Months', type: 'number'},
					{key: 'prop_rentholidayamount', title: 'Rent Holiday Amount', type: 'number'}
				]
			},
			{
				title:"Due Diligence",
				columns:[
					{key: 'dd_property', title: 'Property', type: 'multi'}, //*******type for entity??
					{key: 'dd_leaseextension', title: 'Lease Extension', type: 'number'},
					{key: 'dd_effectivedateofpsa', title: 'Effective Date of PSA', type: 'date'},
					{key: 'dd_amendmentstopsa', title: 'Amendments to PSA?', type: 'select', options:['Yes','No']},
					{key: 'dd_buyingentity', title: 'Buying Entity', type: 'text'},
					{key: 'dd_earnestmoney', title: 'Earnest Money', type: 'number'},
					{key: 'dd_additionaldeposit', title: 'Additional Deposit?', type: 'select', options:['Yes','No']},
					{key: 'dd_inspectiondatestart', title: 'Inspection Date Start', type: 'date'},
					{key: 'dd_inspetiondateend', title: 'Inspection Date End', type: 'date'},
					{key: 'dd_dateofrofrrequestreceived', title: 'Date of ROFR Request / Received', type: 'date'},
					{key: 'dd_snda', title: 'SNDA', type: 'xxxxxx'},
					{key: 'dd_dateofestoppelsrequestreceived', title: 'Date od Estoppels Request / Received', type: 'date'},
					{key: 'dd_expirationdate', title: 'Expiration Date', type: 'date'},
					{key: 'dd_closingdays', title: 'Closing (Days)', type: 'number'},
					{key: 'dd_closingdate', title: 'Closing Date', type: 'date'},
					{key: 'dd_deckcreated', title: 'Deck Created', type: 'text'},
					{key: 'dd_lender', title: 'Lender', type: 'multi'},//*********type for entity??
					{key: 'dd_titlecompany', title: 'Title Company', type: 'text'},
					{key: 'dd_sitevisitdate', title: 'Site Visit (Date)', type: 'date'},
					{key: 'dd_sitevisitwriteup', title: 'Site Visit Writeup', type: 'comments'},///????
					{key: 'dd_checkdotplans', title: 'Check Dot Plans', type: 'text'},
					{key: 'dd_reportedsales', title: 'Reported Sales', type: 'number'},
					{key: 'dd_roofwarrantyxtension', title: 'Roof Warranty-Extension?', type: 'select', options:['Yes','No']},
					{key: 'dd_reviewofleasebyl2', title: 'Review of Lease by L2', type: 'text'},
					{key: 'dd_reviewofamendmentsbyl2', title: 'Review of Amendments by L2', type: 'text'},
					{key: 'dd_reviewofeasementagreementbyl2', title: 'Review of Easement Agreement by L2', type: 'text'},
					{key: 'dd_phasei', title: 'Phase I (ESA)', type: 'date'},
					{key: 'dd_reviewofphaseibyl2', title: 'Review of Phase I by L2', type: 'text'},
					{key: 'dd_propertyinvestigationreport', title: 'Property Investigation Report (PCA)', type: 'date'},
					{key: 'dd_reviewofpcabyl2', title: 'Review of PCA by L2', type: 'text'},
					{key: 'dd_appraisals', title: 'Appraisals', type: 'date'},
					{key: 'dd_reviewofappraisalsbyl2', title: 'Review of Appraisals by L2', type: 'text'},
					{key: 'dd_reviewoftitlecommitmentbyl2', title: 'Review of Title Commitment by L2', type: 'text'},
					{key: 'dd_altasurvey', title: 'Alta Survey', type: 'date'},
					{key: 'dd_reviewofsurveybyl2', title: 'Review of Survey by L2', type: 'text'},
					{key: 'dd_maintenancecreditnotes', title: 'Maintenance Credit / Notes', type: 'comments'},
					{key: 'dd_deliverydate', title: 'Delivery Date', type: 'date'},
					{key: 'dd_senttoseller', title: 'Sent to Seller', type: 'select', options:['Yes','No']},
					{key: 'dd_listingbroker', title: 'Listing Broker', type: 'text'},
					{key: 'dd_changeofownership', title: 'Change of Ownership', type: 'xxxxxx'},
					{key: 'dd_changeofaddressforretaxauthority', title: 'Change of Address for Re Tax Autority', type: 'xxxxxx'},
					{key: 'dd_costsegregation', title: 'Cost Segregation', type: 'number'},
					{key: 'dd_dateordered', title: 'Date Ordered', type: 'date'},
					{key: 'dd_datedue', title: 'Date Due', type: 'date'},
					{key: 'dd_locatedinfloodzone', title: 'Located in Flood Zone', type: 'select', options:['Yes','No']}
				]
			},
			{
				title:"Insurance",
				columns:[
					{key: 'ins_property', title: 'Insurance Property', type: 'multi'},
					{key: 'ins_name', title: 'Insurance Name', type: 'text'},
					{key: 'ins_type', title: 'Insurance Type', type: 'xxxxxx'},
					{key: 'ins_tenantpayforinsurance', title: 'Tenant Pay for Insurance', type: 'xxxxxx'},
					{key: 'ins_tenantissuesforinsurance', title: 'Tenant Issues for Insurance', type: 'xxxxxx'},
					{key: 'ins_reimbursable', title: 'Reimbursable', type: 'xxxxxx'},
					{key: 'ins_policy', title: 'Insurance Policy', type: 'xxxxxx'},
					{key: 'ins_amount', title: 'Insurance Amount', type: 'number'},
					{key: 'ins_policyissuedate', title: 'Policy Issue Date', type: 'date'},
					{key: 'ins_policyrenewaldate', title: 'Policy Renewal Date', type: 'date'},
					{key: 'ins_invoicedate', title: 'Invoice Date', type: 'date'},
					{key: 'ins_paymentreceived', title: 'Insurance Payment Received', type: 'xxxxxx'},
					{key: 'ins_paid', title: 'Paid', type: 'xxxxxx'},
					{key: 'ins_comments', title: 'Insurance Comments', type: 'comments'},
					{key: 'ins_location', title: 'Location', type: 'text'},
					{key: 'ins_effectivedate', title: 'Insurance Effective Date', type: 'date'},
					{key: 'ins_llprogram-coi', title: 'LL Program - COI', type: 'xxxxxx'},
					{key: 'ins_firealarm', title: 'Fire Alarm', type: 'xxxxxx'},
					{key: 'ins_tenantresponsibility', title: 'Tenant Responsibility', type: 'xxxxxx'},
					{key: 'ins_alarmcompany', title: 'Alarm Company', type: 'multi'},
					{key: 'ins_inspectiondate', title: 'Inspection Date', type: 'date'},
					{key: 'ins_paiddate', title: 'Paid Date', type: 'date'},
					{key: 'ins_expirationdate', title: 'Insurance Expiration Date', type: 'date'}
				]
			},
			{
				title:"Warranty",
				columns:[
					{key: 'warr_property', title: 'Warranty Property', type: 'multi'},
					{key: 'warr_warrantycompany', title: 'Warranty Company', type: 'multi'},
					{key: 'warr_warrantynumber', title: 'Warranty Number', type: 'number'},
					{key: 'warr_expirationdate', title: 'Warranty Expiration Date', type: 'date'},
					{key: 'warr_installdate', title: 'Install Date', type: 'date'},
					{key: 'warr_company', title: 'Company', type: 'multi'}
				]
			},
			{
				title:"Taxes",
				columns:[
					{key: 'taxes_property', title: 'Taxes Property', type: 'multi'},
					{key: 'taxes_amount', title: 'Taxes Amount', type: 'number'},
					{key: 'taxes_duedate', title: 'Taxes Due Date', type: 'date'},
					{key: 'taxes_reimbursable', title: 'Taxes Reimbursable', type: 'xxxxxx'},
					{key: 'taxes_invoicedate', title: 'Taxes Invoice Date', type: 'date'},
					{key: 'taxes_paymentreceived', title: 'Taxes Payment Received', type: 'number'},
					{key: 'taxes_paid', title: 'Taxes Paid', type: 'xxxxxx'},
					{key: 'taxes_comments', title: 'Taxes Comments', type: 'xxxxxx'}
				]
			},
			{
				title:"Maintenance",
				columns:[
					{key: 'mnnt_property', title: 'Maintenance Property', type: 'multi'},
					{key: 'mnnt_amount', title: 'Maintenance Amount', type: 'number'},
					{key: 'mnnt_item', title: 'Maintenance Item', type: 'xxxxxx'},
					{key: 'mnnt_reimbursable', title: 'Maintenance Reimbursable', type: 'xxxxxx'},
					{key: 'mnnt_invoicedate', title: 'Maintenance Invoice Date', type: 'date'},
					{key: 'mnnt_paymentreceived', title: 'Maintenance Payment Received', type: 'xxxxxx'},
					{key: 'mnnt_paid', title: 'Maintenance Paid', type: 'xxxxxx'},
					{key: 'mnnt_comments', title: 'Maintenance Comments', type: 'comments'},
					{key: 'mnnt_duedate', title: 'Maintenance Due Date', type: 'date'}
				]

			},
			{
				title:"LLC",
				columns:[
					{key: 'llc_property', title: 'LLC Property', type: 'multi'},
					{key: 'llc_owner', title: 'LLC Owner', type: 'multi'},
					{key: 'llc_duedate', title: 'LLC Due Date', type: 'date'},
					{key: 'llc_reimbursable', title: 'LLC Reimbursable', type: 'xxxxxx'},
					{key: 'llc_amount', title: 'LLC Amount', type: 'number'},
					{key: 'llc_comments', title: 'LLC Comments', type: 'comments'},
					{key: 'llc_invoicedate', title: 'LLC Invoice Date', type: 'date'},
					{key: 'llc_paid', title: 'LLC Paid', type: 'xxxxxx'},
					{key: 'llc_accountnumber', title: 'LLC Account Number', type: 'number'}
				]

			},
			{
				title:"Foreign LLC",
				columns:[
					{key: 'fllc_property', title: 'Foreign LLC Property', type: 'multi'},
					{key: 'fllc_owner', title: 'Foreign LLC Owner', type: 'multi'},
					{key: 'fllc_duedate', title: 'Foreign LLC Due Date', type: 'date'},
					{key: 'fllc_reimbursable', title: 'Foreign LLC Reimbursable', type: 'xxxxxx'},
					{key: 'fllc_amount', title: 'Foreign LLC Amount', type: 'number'},
					{key: 'fllc_comments', title: 'Foreign LLC Comments', type: 'comments'},
					{key: 'fllc_invoicedate', title: 'Foreign LLC Invoice Date', type: 'date'},
					{key: 'fllc_paid', title: 'Foreign LLC Paid', type: 'xxxxxx'},
					{key: 'fllc_accountnumber', title: 'Foreign LLC Account Number', type: 'number'}
				]

			},
			{
				title:"GMEI",
				columns:[
					{key: 'gmei_property', title: 'GMEI Property', type: 'multi'},
					{key: 'gmei_owner', title: 'GMEI Owner', type: 'multi'},
					{key: 'gmei_duedate', title: 'GMEI Due Date', type: 'date'},
					{key: 'gmei_reimbursable', title: 'GMEI Reimbursable', type: 'xxxxxx'},
					{key: 'gmei_amount', title: 'GMEI Amount', type: 'number'},
					{key: 'gmei_comments', title: 'GMEI Comments', type: 'comments'},
					{key: 'gmei_invoicedate', title: 'GMEI Invoice Date', type: 'date'},
					{key: 'gmei_paid', title: 'GMEI Paid', type: 'xxxxxx'},
					{key: 'gmei_accountnumber', title: 'GMEI Account Number', type: 'number'}
				]

			},
			{
				title:"Foreign Agent Renewall",
				columns:[
					{key: 'fagent_property', title: 'Foreign Agent Property', type: 'multi'},
					{key: 'fagent_duedate', title: 'Foreign Agent Due Date', type: 'date'},
					{key: 'fagent_reimbursable', title: 'Foreign Agent Reimbursable', type: 'xxxxxx'},
					{key: 'fagent_amount', title: 'Foreign Agent Amount', type: 'number'},
					{key: 'fagent_comments', title: 'Foreign Agent Comments', type: 'comments'},
					{key: 'fagent_invoicedate', title: 'Foreign Agent Invoice Date', type: 'date'},
					{key: 'fagent_paid', title: 'Foreign Agent Paid', type: 'xxxxxx'},
					{key: 'fagent_accountnumber', title: 'Foreign Agent Account Number', type: 'number'},
					{key: 'fagent_agent', title: 'Agent', type: 'multi'}
				]

			}
		],
		vendors:
		[
			{
				title:"Vendor",
				columns:[
					{key: 'vndr_address', title: 'Vendor Address', type: 'multi'},
					{key: 'vndr_telephone', title: 'Vendor Telephone', type: 'multi'},
					{key: 'vndr_email', title: 'Vendor Email', type: 'multi'},
					{key: 'vndr_name', title: 'Vendor Name', type: 'text'},
					{key: 'vndr_log', title: ' Log', type: 'comments'},
					{key: 'vndr_prefered', title: 'Prefered', type: 'xxxxxx'},
					{key: 'vndr_types', title: 'Vendor type', type: 'xxxxxx'},
					{key: 'vndr_services', title: 'Vendor Services', type: 'comments'},
					{key: 'vndr_paymentfrequency', title: 'Vendor Payment Frecuency', type: 'select', options:['Monthly','Quartely']},
					{key: 'vndr_paymentmethod', title: 'Vendor Payment Method', type: 'select', options:['Cash','Deposit']},
					{key: 'vndr_snowiceremoval', title: 'Snow / Ice Removal', type: 'select', options:['Yes','No']},
					{key: 'vndr_Property', title: 'Vendor Property', type: 'multi'}
				]
			},
		],
		tickets:
		[
			{
				title:"Ticket",
				columns:[
					{key: 'ticket_property', title: 'Ticket Property', type: 'multi'},
					{key: 'ticket_number', title: 'Ticket Number', type: 'number'},
					{key: 'ticket_amount', title: 'Ticket Amount', type: 'number'},
					{key: 'ticket_approvedby', title: 'Ticket Approved by', type: 'multi'},//contains users
					{key: 'ticket_approvaldate', title: 'Approval Date', type: 'date'},
					{key: 'ticket_comments', title: 'Ticket Comments', type: 'comments'},
					{key: 'ticket_documents', title: 'Documents', type: 'comments'},
					{key: 'ticket_issue', title: 'Issue', type: 'comments'},
					{key: 'ticket_description', title: 'Description', type: 'comments'},
					{key: 'ticket_vendor', title: 'Ticket Vendor', type: 'multi'},
					{key: 'ticket_createdby', title: 'Ticket Created by', type: 'multi'},
					{key: 'ticket_expectedstartdate', title: 'Expected Start Date', type: 'date'},
					{key: 'ticket_expectedcompletiondate', title: 'Expected Completion Date', type: 'date'},
					{key: 'ticket_actualstartdate', title: 'Actual Start Date', type: 'date'},
					{key: 'ticket_actualcompletiondate', title: 'Actual Completion Date', type: 'date'},
					{key: 'ticket_tenant', title: 'Ticket Tenant', type: 'multi'},
					{key: 'ticket_warranty', title: 'Ticket Warranty', type: 'multi'},
					{key: 'ticket_client', title: 'Ticket Client', type: 'multi'},
					{key: 'ticket_paymentmethod', title: 'Ticket Payment Method', type: 'select', options:['Bill Pay','Client direct','Walgreens/CVS Direct']},
					{key: 'ticket_statusnotes', title: 'Ticket Status Notes', type: 'comments'},
					{key: 'ticket_typeofrepairorrequest', title: 'Type of Repair or Request', type: 'select', options:['Roof','EFIS','Parking','Exterior','Other']},
					{key: 'ticket_whomreportedissue', title: 'Whom Reported Issue', type: 'text'}
				]
			},
		],
		clients:
		[
			{
				title:"Client",
				columns:[
					{key: 'cli_address', title: 'Client Address', type: 'multi'},
					{key: 'cli_telephone', title: 'Client Telephone', type: 'multi'},
					{key: 'cli_email', title: 'Client Email', type: 'multi'},
					{key: 'cli_name', title: 'Client Name', type: 'text'},
					{key: 'cli_status', title: 'Client Comments', type: 'select', options:['Active','Inactive','Advisor','Lender','Unknown']},
					{key: 'cli_comments', title: 'Client Rank', type: 'comments'},
					{key: 'cli_advisor', title: 'Advisor', type: 'text'}
				]
			},

		]
	},
	entities: {
		address: {
			title:"Address",
			columns:[
				{key: 'addr_street1', title: 'Street 1', type: 'text'},
				{key: 'addr_street2', title: 'Street 2', type: 'text'},
				{key: 'addr_city', title: 'City', type: 'text'},
				{key: 'addr_state', title: 'Sate', type: 'text'},
				{key: 'addr_zipcode', title: 'Zip Code', type: 'number'},
				{key: 'addr_country', title: 'Country', type: 'text'}
			]
		},
		telephone: {
			title:"Telephone",
			columns:[
				{key: 'phone_number', title: 'Telephone', type: 'text'},
				{key: 'phone_type', title: 'Telephone type', type: 'select', options:['Home','Work','Cell Phone','Main','Other']}
			]
		},
		email: {
			title:"Email",
			columns:[
				{key: 'email_number', title: 'Email', type: 'text'},
				{key: 'email_type', title: 'Email type', type: 'select', options:['Personal','Work','Other']}
			]
		},
		cam: {
			title:"CAM",
			columns:[
				{key: 'cam_association', title: 'Assocition', type: 'multi'},
				{key: 'cam_landscape', title: 'Landscape', type: ''},
				{key: 'cam_parkinglot', title: 'Parking Lot', type: 'xxxxxx'},
				{key: 'cam_monthlyamount', title: 'Monthly Amount', type: 'number'},
				{key: 'cam_reimbursementsubmitted', title: 'Reimbursement Submitted', type: 'xxxxxx'},
				{key: 'cam_datesubmitted', title: 'Date Submitted', type: 'date'},
				{key: 'cam_reimbursedbytenant', title: 'Reimbursed by Tenant', type: 'select', options:['Yes','No']},
				{key: 'cam_reimbursementdate', title: 'Reimbursement Date', type: 'date'},
				{key: 'cam_vendor', title: 'CAM Vendor', type: 'multi'}
			]
		},
		owner :{
			title:"Owner",
			columns:[
				{key: 'own_address', title: 'Owner Address', type: 'multi'},
				{key: 'own_telephone', title: 'Owner Telephone', type: 'multi'},
				{key: 'own_email', title: 'Owner Email', type: 'multi'},
				{key: 'own_name', title: 'Owner Name', type: 'text'}
			]
		},
		tenant: {
			title:"Tenant",
			columns:[
				{key: 'tnt_address', title: 'Tenant Address', type: 'multi'},
				{key: 'tnt_telephone', title: 'Tenant Telephone', type: 'multi'},
				{key: 'tnt_email', title: 'Tenant Email', type: 'multi'},
				{key: 'tnt_name', title: 'Tenant Name', type: 'text'}
			]
		},
		prospect: {
			title:"Prospect",
			columns:[
				{key: 'pros_address', title: 'Prospect Address', type: 'multi'},
				{key: 'pros_telephone', title: 'Prospect Telephone', type: 'multi'},
				{key: 'pros_email', title: 'Prospect Email', type: 'multi'},
				{key: 'pros_name', title: 'Prospect Name', type: 'text'},
				{key: 'pros_comments', title: 'Prospect Comments', type: 'comments'},
				{key: 'pros_rank', title: 'Prospect Rank', type: 'xxxxxx'},
				{key: 'pros_referralsource', title: 'Referral Source', type: 'xxxxxx'}
			]
		},
		warranty_company: {
			title:"Warranty Company",
			columns:[
				{key: 'warcomp_address', title: 'Warranty Company Address', type: 'multi'},
				{key: 'warcomp_telephone', title: 'Warranty Company Telephone', type: 'multi'},
				{key: 'warcomp_email', title: 'Warranty Company Email', type: 'multi'},
				{key: 'warcomp_name', title: 'Warranty Company Name', type: 'text'}
			]
		},
		association: {
			title:"Association",
			columns:[
				{key: 'associtn_address', title: 'Association Address', type: 'multi'},
				{key: 'associtn_telephone', title: 'Association Telephone', type: 'multi'},
				{key: 'associtn_email', title: 'Association Email', type: 'multi'},
				{key: 'associtn_name', title: 'Association Name', type: 'text'}
			]
		},
		company: {
			title:"Company",
			columns:[
				{key: 'co_address', title: 'Company Address', type: 'multi'},
				{key: 'co_telephone', title: 'Company Telephone', type: 'multi'},
				{key: 'co_email', title: 'Company Email', type: 'multi'},
				{key: 'co_name', title: 'Company Name', type: 'text'}
			]
		},
		agent: {
			title:"Agent",
			columns:[
				{key: 'agent_address', title: 'Agent Address', type: 'multi'},
				{key: 'agent_telephone', title: 'Agent Telephone', type: 'multi'},
				{key: 'agent_email', title: 'Agent Email', type: 'multi'},
				{key: 'agent_name', title: 'Agent Name', type: 'text'}
			]
		},
		lender: {
			title:"Lender",
			columns:[
				{key: 'lender_address', title: 'Lender Address', type: 'multi'},
				{key: 'lender_telephone', title: 'Lender Telephone', type: 'multi'},
				{key: 'lender_email', title: 'Lender Email', type: 'multi'},
				{key: 'lender_name', title: 'Lender Name', type: 'text'}
			]
		},
		alarm_company: {
			title:"Alarm Company",
			columns:[
				{key: 'alarmco_address', title: 'Alarm Company Address', type: 'multi'},
				{key: 'alarmco_telephone', title: 'Alarm Company Telephone', type: 'multi'},
				{key: 'alarmco_email', title: 'Alarm Company Email', type: 'multi'},
				{key: 'alarmco_name', title: 'Alarm Company Name', type: 'text'}
			]
		}
	}
}*/
