
angular.module('ezTable.controllers')

.controller('RowKeyCtrl', ['$scope', '$uibModalInstance', 'currentRow', 'currentItem', 'Users', 'FirebaseRef', '$firebaseObject', 'AclService', '$firebaseArray', function($scope, $uibModalInstance, currentRow, currentItem, Users, FirebaseRef, $firebaseObject, AclService, $firebaseArray){
    $scope.row = currentRow;
    $scope.item = currentItem;
    $scope.can = AclService.can;
    var business = Users.getCurrentBusiness(),
        businessRef = FirebaseRef.child('businesses/' + business);

    function setCol(col) {
      if(angular.isDefined(col)) {
        $scope.col = col;
      }
    }

    (function () {
        var getDBList = function (list) {
            var tmpList = [];
            angular.forEach(list, function (value, key) {
                var id = key,
                    ref = businessRef.child('information/' + $scope.item.key).child(id),
                    obj = $firebaseObject(ref);
                obj.$loaded().then(function (o) {
                    tmpList.push(o);
                });
            });
            return tmpList;
        }

        var createGroups = function (columns) {
            var groups = [
                {
                    type: 'normal',
                    columns: []
                },
                {
                    type: 'special',
                    columns: []
                },
                {
                    type: 'entities',
                    columns: []
                }
            ];
            angular.forEach(columns, function (c) {
                if (c.type == 'file' || c.type == 'checklist' || c.type == 'notes') {
                    groups[1].columns.push(c);
                } else if (c.type == 'entity') {
                    groups[2].columns.push(c);
                } else {
                    groups[0].columns.push(c);
                }
            });
            return groups;
        }

        var entityRef = businessRef.child('default/entities').child($scope.item.key),
            entity = $firebaseObject(entityRef);

        entity.$loaded().then(function (entity) {
            $scope.table = entity.table;
            $scope.groups = createGroups(entity.columns);

            /* Load current row records*/
            var recordsRef = businessRef.child('information').child($scope.item.key);
              recordsRef.once('value', (records) => {
                let recordsValue = records.val();
                $scope.recArray = [];
                if (recordsValue) {
                  for(var key in $scope.row[$scope.item.key]) {
                    if(recordsValue.hasOwnProperty(key)) {
                      $scope.recArray.push(recordsValue[key]);
                    }
                  }
                }
                $scope.currentEntity = {};
              });
        });

    } ());

    $scope.changeCurrentEntity = function(index) {
      $scope.currentEntity = $scope.recArray[index];
      delete $scope.currentEntity.$$hashKey;
      businessRef.child('information/' + $scope.item.key).once("value", function(entity) {
        let entityObj = entity.val();
        for(key in entityObj) {
          if(angular.toJson(entityObj[key]) == angular.toJson($scope.currentEntity)) {
            $scope.currentEntityKey = key;
            break;
          }
        }
      });
    }

    $scope.resetEntity = function() {
      $scope.currentEntity = {};
    }

    $scope.groupAvailable = function (group) {
        return group.columns.length > 0;
    }

    $scope.saveEntityContent = function(){
      $scope.updateItem($scope.row, function(refK) {
        let entityExists;
        let entityExistsRef;


        if(!$scope.currentEntity.refK) {
          entityExists = false;
          let newEntityRef = businessRef.child('information/' + $scope.item.key).push();
          let newEtityKey = newEntityRef.key;
          let updateEntityData = {};
          $scope.currentEntity["refK"] = refK;
          updateEntityData[$scope.item.key + '/' + newEtityKey] = $scope.currentEntity;
          updateEntityData[$scope.$parent.section + '/' + refK + '/' + $scope.item.key + '/' + newEtityKey] = true;
          businessRef.child('information/').update(updateEntityData, function(err) {
            if(err) console.log(err);
          });
        } else {
          businessRef.child('information/' + $scope.item.key).child($scope.currentEntityKey).update($scope.currentEntity, function(err) {
            if(err) console.log(err);
          });
        }

      });
      $scope.close();
    }

    $scope.close = function(){
		$uibModalInstance.close(false);
	}
}]);
