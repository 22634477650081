angular.module('ezTable.services')
.service('FireUpload', function(StorageRef, $rootScope) {
  return {
    upload: function(reference, file) {
      return new Promise(function(resolve, reject) {
          var uploadTask = StorageRef.child(reference).child(moment().format('x') + file.name).put(file);

          uploadTask.on('state_changed', function(snapshot) {
            var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            $rootScope.uploadProgress = progress > 0 ? progress : 1;
            $rootScope.$apply();
          }, function(error) {
            switch (error.code) {
              case 'storage/unauthorized':
                // User doesn't have permission to access the object
                toastr.error('Not enought access to continue', 'Error');
                break;
              case 'storage/canceled':
                // User canceled the upload
                toastr.error('File upload was canceled', 'Error');
                break;
              case 'storage/unknown':
                // Unknown error occurred, inspect error.serverResponse
                toastr.error('There was an error', 'Error');
                break;
            }
            reject('There was an error');
          }, function() {
            $rootScope.uploadProgress = 0;
            toastr.success('The file was uploaded successfully', 'Completed');
            var downloadURL = uploadTask.snapshot.downloadURL;
            resolve(downloadURL);
          });
      });
    }
  }
});
