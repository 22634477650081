angular.module('ezTable.directives')

.directive('files', ['Users', 'StorageRef', '$timeout', 'AclService', function(Users, StorageRef, $timeout, AclService){
	return{
		restrict: 'E',
		replace: true,
        scope: {
            parent: '=',
            target: '=',
            update: '=',
            item: '='
		},
		templateUrl: '/partials/directives/files.html',
        link: function (scope, element, attrs) {

            scope.can = AclService.can;

            scope.progress = 0;
            scope.uploading = false;

            var getTotalBytesSize = function(filesArray){
                var total = 0;
                for(var i = 0; i < filesArray.length; i++){
                    total += filesArray[i].size;
                }
                return total;
            }

            scope.uploadFiles = function(files){
                scope.progress = 0;
                var business = Users.getCurrentBusiness(),
                    user = Users.getCurrentUser().$id,
                    totalSize = getTotalBytesSize(files),
                    totalBytesTransfered = 0, realProgress = 0;

                var saveFileRef = function (url, file) {
                    if (!angular.isDefined(_.get(scope.parent, scope.target))) {
                        var aux = {
                            files: []
                        };
                        _.set(scope.parent, scope.target, aux);

                    }

                    scope.item = _.get(scope.parent, scope.target);
                    scope.item.files.push({
                        user: user,
                        name: file.name,
                        url: url
                    });
                    scope.update ? scope.$parent.updateItem(scope.parent) : '';
                    toastr.success(file.name + " uploaded");
                }

                angular.forEach(files, function(file) {
                    var fileRef = StorageRef.child(business).child('files/' + file.name);
                    fileRef.getDownloadURL().then(function(foundURL){
                        saveFileRef(foundURL, file);
                    }, function(error){
                        var uploadTask = fileRef.put(file);
                        // Listen for state changes, errors, and completion of the upload.
                        uploadTask.on('state_changed', function (snapshot) {
                            scope.uploading = true;
                            totalBytesTransfered += snapshot.bytesTransferred;
                            realProgress = (totalBytesTransfered / totalSize) * 100;
                            if(realProgress >= 100){
                                realProgress = 100;
                                scope.uploading = false;
                                $timeout(function () {
                                    scope.progress = 0;
                                }, 2000);
                            }
                            scope.progress = Math.trunc(realProgress);
                            scope.$apply();
                            switch (snapshot.state) {
                                case 'paused': // or 'paused'
                                    console.log('Upload is paused');
                                    break;
                                case 'running': // or 'running'
                                    //console.log('Upload is running');
                                    break;
                            }
                        }, function(error) {
                            switch (error.code) {
                                case 'storage/unauthorized':
                                    // User doesn't have permission to access the object
                                    break;
                                case 'storage/canceled':
                                    // User canceled the upload
                                    break;
                                case 'storage/unknown':
                                    // Unknown error occurred, inspect error.serverResponse
                                    break;
                            }
                        }, function() {
                            // Upload completed successfully, now we can get the download URL
                            var downloadURL = uploadTask.snapshot.downloadURL;
                            saveFileRef(downloadURL, file);
                        });
                    });
                });
            }
        }
	}
}]);