
angular.module('ezTable.controllers')

.controller('RowDetailCtrl', ['$scope', '$uibModalInstance', 'currentRow', 'Users', 'FirebaseRef', '$firebaseArray', function($scope, $uibModalInstance, currentRow, Users, FirebaseRef, $firebaseArray){
    $scope.row = currentRow;

    (function(){

        var createGroups = function(){
            var groups = [],
                group = {};
            angular.forEach($scope.groups, function(g){
                if(g.label === "Default"){
                    angular.forEach(g.tables, function(t){
                        group.title = t.title;
                        group.columns = t.columns;
                        groups.push(group);
                        group = {};
                    });
                }
            });
            return groups;
        }

        var groups = createGroups();

        var business = Users.getCurrentBusiness(),
            columnsRef = FirebaseRef.child('businesses/' + business).child('custom-columns/' + $scope.section),
            addedColumns = $firebaseArray(columnsRef);
        addedColumns.$loaded().then(function(columns){
            var extra = {
                title: 'Custom Columns',
                columns: columns
            }
            $scope.modalGroups = _.concat(groups, extra);
        });
    }());

    $scope.typeAvailable = function(type, columns){
        var filter = _.filter(columns, {type: type});
        return filter.length > 0;
    }

    $scope.close = function(){
		$uibModalInstance.close(false);
	}
}]);
