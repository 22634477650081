
angular.module('ezTable.controllers')

.controller('LandingPageCtrl', ['$scope', '$timeout', '$state', 'Auth', 'Users', 'FirebaseRef', function($scope, $timeout, $state, Auth, Users, FirebaseRef){

	$scope.signin = true;
	$scope.error = null;

	$scope.toggleSignIn = function(){
		$scope.signin = !$scope.signin;
	}

	$scope.handleError = function(error){
		$scope.error = error.message;
	}

	$scope.handleLogin = function(firebaseUser, user){
		var currentUser = Users.getCurrentUser(),
			auth = Auth.$getAuth();
		var ref = FirebaseRef.child('users/' + currentUser.$id);
		ref.update({
			email: auth.email,
			created_at: moment().format(),
			displayName: auth.displayName !== null ? auth.displayName : user.name,
			photoURL: auth.photoURL !== null ? auth.photoURL : "http://www.pdhbook.com/Images/User/default_image/User_Placeholder.png"
		});
		$state.go('dashboard');
	}

	$scope.signinGoogle = function(){
		Auth.$signInWithPopup('google')
			.then( function(firebaseUser){ $scope.handleLogin(firebaseUser, '') } )
			.catch(function(error){ $scope.error = error.message; } );
	}

	$scope.$watch("error", function(value){
		if(value){
			$timeout(function(){
				$scope.error = null;
			}, 5000)
		}
	});

}]);