angular.module('ezTable.filters')

.filter('setFormat', function () {
  return function (number, col) {
    var toShow = number;
    switch (col.format) {
      case 'currency':
        toShow = accounting.formatMoney(number);
        break;
      case 'percentage':
        toShow = accounting.formatMoney(number, '%', 2, '')
    }
    return toShow;
  }
});
