var SmartTableWindowCtrl = function($scope, $timeout, FirebaseRef, $firebaseArray, $state, Users){

	$scope.tabs = [
		{
			title: 'Default',
			tables: null
		},
		{
			title: 'Business',
			tables: null
		},
		{
			title: 'User',
			tables: null
		}
	];

	$scope.toggleCustomWizard = function(){
		$scope.creating = !$scope.creating;
	}

	$scope.creating = false;
	$scope.loaded = false;
	$scope.activeTab = 0;
	$scope.globalSearch = "";

	Users.getCurrentUser().$loaded().then(function(data){
		var business = data.currentBusiness,
			user = data.$id;

		var businessRef = FirebaseRef.child('businesses/' + business),
			businessDefaultTablesRef = businessRef.child('default/eztables/' + $state.current.name),
			businessTablesRef = businessRef.child('eztables/' + $state.current.name),
			userTablesRef = FirebaseRef.child('users/' + user).child('businesses/' + business).child('eztables/' + $state.current.name),
			rowsRef = businessRef.child($state.current.name);

		$scope.groups = [
			{
				label: "Default",
				tables: $firebaseArray(businessDefaultTablesRef)
			},
			{
				label: "Business",
				tables: $firebaseArray(businessTablesRef)
			},
			{
				label: 'User',
				tables: $firebaseArray(userTablesRef)
			}
		];

		angular.forEach($scope.tabs, function(tab){
			tab.tables.$loaded().then(function(){
				if(tab.tables.length === 0){
					return false;
				}
				tab.selected = tab.tables[0].$id;
				$scope.getTable(tab);
			});
		});
		$timeout(function(){
			$scope.loaded = true;
			$scope.activeTab = 0;
		}, 200);
	});

	$scope.getTable = function(object){
		//For UI
		object.changing = true;
		var tables = object.tables;
		var tmp_array = tables.filter(function(element){
			if(element.$id === object.selected){
				return element;
			}
		});
		object.counter = tables.indexOf(tmp_array[0]);
		$timeout(function(){
			object.changing = false;
		}, 500)
	}

	$scope.selectTabTable = function(tab, newTable){
		$scope.creating = false;
		$timeout(function(){
			$scope.activeTab = tab;
			$scope.tabs[tab].selected = newTable;
			$scope.getTable($scope.tabs[tab]);
		}, 200);
	}
}

SmartTableWindowCtrl.$inject = ['$scope', '$timeout', 'FirebaseRef', '$firebaseArray', '$state', 'Users'];

angular.module('ezTable.controllers')

.controller('SmartTableWindowCtrl', SmartTableWindowCtrl);