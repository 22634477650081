
angular.module('ezTable.controllers')

.controller('LoginCtrl', ['$scope', '$state', '$timeout', 'Auth', 'FirebaseRef', function($scope, $state, $timeout, Auth, FirebaseRef){

	var handleLogin = function(user){
		$state.go('dashboard');
	}

	$scope.signin = function(){
		if(!$scope.signinForm.$valid){
			return false;
		}
		Auth.$signInWithEmailAndPassword($scope.user.email, $scope.user.password)
			.then(function(firebaseUser){ handleLogin(firebaseUser) })
			.catch(function(error){ $scope.handleError(error) });
	}

}]);